import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import axios from "axios";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { IoIosArrowBack } from "react-icons/io";
import PageLoader from "../../Components/Loader/PageLoader";

const defaultUser = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
};

const TrialLogin = () => {
  const id = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [plan, setPlan] = useState();
  const location = useLocation();
  const [user, setUser] = useState(defaultUser);
  const [errors, setErrors] = useState({});
  const [proceed, setProceed] = useState(false);


  const handleRegister = () => {
    setIsLoading(true);
    setErrors({});
    axios
      .post(`${Helpers.apiUrl}auth/register`, user)
      .then((response) => {
        Helpers.toast("success", response.data.message);
        Helpers.setItem("user", response.data.user, true);
        Helpers.setItem("token", response.data.token);
        Helpers.setItem("id", response.data.user.id);
        setIsLoading(false);
        navigate("/auth/login");
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data || {});
          Helpers.toast(
            "error",
            error.response.data.email ||
            error.response.data.password ||
            error.response.data.name
          );
        }
        setIsLoading(false);
      });
  };

  const ProceedPayment = () => {
    if(user.email === "" || user.name === "" || user.password === "" || user.password_confirmation === ""){
      console.log(user)
      Helpers.toast("error", "Please Fill the Registeration Details")
      return;
    }
    setProceed(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      console.error("Stripe has not loaded");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: user.email,
        },
      });

    if (paymentMethodError) {
      Helpers.toast('error', paymentMethodError.message)
      console.error(paymentMethodError);
      return;
    }
    const { token } = await stripe.createToken(elements.getElement(CardElement));
    if(token || paymentMethod){
        let data = {
            plan: plan.id,
            amount: amount,
            name: user.name,
            email: user.email,
            password: user.password,
            password_confirmation: user.password_confirmation,
            source: token.id,
            payment_method: token.id,
            paymentMethod: paymentMethod.id
        }
        axios.post(`${Helpers.apiUrl}auth/activate/trial`, data).then(response => {
            Helpers.toast("success", response.data.message);
            Helpers.setItem("user", response.data.user, true);
            Helpers.setItem("token", response.data.token);
            Helpers.setItem("id", response.data.user.id);
            console.log(response);
            setTimeout(() => {
                window.location.href = '/user/dashboard';
            }, 1000);
        }).catch(error => {
            if(error.response){
                Helpers.toast("error", error.response.data.message);
            }else{
                Helpers.toast("error", "Unexpected error occured");
            }
            setIsLoading(false);
        });
    }else{
        Helpers.toast("error", "Unexpected error occured");
        setIsLoading(false);
    }
}

  const getSingle = () => {
    axios.get(`${Helpers.apiUrl}pricing/getOne/${id.id}`).then(res=>{
      if(res.data.plan.monthly_price == 0){
        Helpers.toast('error','Kindly Subscribe another Package')
        navigate('/user/dashboard')
      }
      setPageLoading(true);
      setPlan(res.data.plan)
      setAmount(parseFloat(res.data.plan.monthly_price));
    }).catch(err=>{
      Helpers.toast('error', err.message);
    })
  }

  useEffect(()=>{
    getSingle();
  }, [])
  return (
    <>
      {!proceed &&(<div className="tyn-root " style={{ background: "#F8F8F8" }}>
      <div className="tyn-content tyn-auth tyn-auth-centered" style={{minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="my-3 text-center">
                  <img src="/app/Legaldrafting.png" className="w200" />
                </div>
                <div
                  className="card border-0 card-custom"
                  style={{ background: "white" }}
                >
                  <div className="p-4">
                    <h3 className="white" style={{color: "black"}}>Create Free Account</h3>
                    <div className="row g-3">
                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label white-sub" style={{color: "black"}}>Your Name</label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              value={user.name}
                              onChange={(e) =>
                                setUser({ ...user, name: e.target.value })
                              }
                              className="form-control "
                              placeholder="Your Name"
                            />
                            <small className="text-danger">
                              {errors.name ? errors.name[0] : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label white-sub" style={{color: "black"}}>
                            Email Address
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              value={user.email}
                              onChange={(e) =>
                                setUser({ ...user, email: e.target.value })
                              }
                              className="form-control "
                              placeholder="youremail@example.com"
                            />
                            <small className="text-danger">
                              {errors.email ? errors.email[0] : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label d-flex white-sub" style={{color: "black"}}>
                            Password
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="password"
                              value={user.password}
                              onChange={(e) =>
                                setUser({ ...user, password: e.target.value })
                              }
                              className="form-control "
                              placeholder="Password"
                            />
                            <small className="text-danger">
                              {errors.password ? errors.password[0] : ""}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label className="form-label d-flex white-sub" style={{color: "black"}}>
                            Confirm Password
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="password"
                              value={user.password_confirmation}
                              onChange={(e) =>
                                setUser({
                                  ...user,
                                  password_confirmation: e.target.value,
                                })
                              }
                              className="form-control "
                              placeholder="Confirm Password"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          onClick={ProceedPayment}
                          disabled={isLoading}
                          className="btn w-100"
                          style={{ background: "#0C1B44", color: 'white' }}
                        >
                          {isLoading
                            ? "Creating Your Account"
                            : "Next"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <p className="small white-sub  " style={{color: "black"}}>
                    Already have an account?{" "}
                    <Link className="color-primary" to="/auth/login">
                      Login to Account
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)}

      {proceed &&(
      <div className=" tyn-root" style={{backgroundColor: "white"}}>
        {/* <button
        className="theme-btn m-2" 
        style={{width: "10%", textWrap: "nowrap"}}
        onClick={()=>setProceed(false)}
        > */}
          <IoIosArrowBack
          className="m-2"  
          onClick={()=>setProceed(false)}
          size={'2.5vw'}
          style={{cursor: "pointer"}}/> 
          {/* Go Back */}
        {/* </button> */}
        <div className="tyn-content tyn-auth tyn-auth-centered">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
              <div className="my-3 text-center">
                                <img src="/app/Legaldrafting.png" className="w200" />
                            </div>
                            
                { !(pageLoading) ? <PageLoader/> :
                (<div className="card border-0 card-custom" style={{ background: 'white' }}>
                  <div className="p-4">
                    <h3 className=" text-center text-black ">Subscribe Now</h3>
                    <form onSubmit={handleSubmit} className="row g-3">
                      <div className="col-12">
                        <div className="form-group">
                          <label
                            htmlFor="amount"
                            className="form-label text-black"
                          >
                            Subscribtion ($):
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="number"
                              id="amount"
                              className="form-control "
                              value={amount}
                              disabled={true}
                              placeholder="Enter amount"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label
                            htmlFor="email"
                            className="form-label text-black"
                          >
                            Email:
                          </label>
                          <div className="form-control-wrap">
                            <input
                              type="email"
                              id="email"
                              className="form-control "
                              value={user.email}
                              disabled={true}
                              placeholder="Enter your email"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <label
                            htmlFor="card-details"
                            className="form-label text-black"
                          >
                            Card Details:
                          </label>
                          <div className="form-control-wrap">
                            <CardElement
                              id="card-details"
                              className="form-control "
                              options={{
                                style: {
                                  base: {
                                    fontSize: "16px",
                                    color: "black",
                                    "::placeholder": {
                                      color: "black",
                                    },
                                  },
                                  invalid: {
                                    color: "#fa755a",
                                  },
                                },
                              }}
                            />
                          </div>
                        
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                        disabled={isLoading}
                        type="submit" 
                        className="btn w-100  " 
                        style={{ background: "#0C1B44" , color : 'white' }}>
                          {isLoading ? 'Loading...': "Subscribe"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>)}

              </div>
            </div>
          </div>
        </div>
      </div>)}
    </>
  );
};

export default TrialLogin;
