import { useContext, useEffect } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import { ChatsContext } from "../../Contexts/ChatsContext";
import axios from "axios";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faHeart, faL } from "@fortawesome/free-solid-svg-icons";
import { FaLongArrowAltLeft, FaLongArrowAltRight, FaFileWord  } from "react-icons/fa";
import { RiListRadio } from "react-icons/ri";
import { IoMdList,IoMdRadioButtonOn } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { HiCurrencyDollar } from "react-icons/hi2";
import { IoPersonSharp, IoSettings } from "react-icons/io5";
import { ImBlog } from "react-icons/im";
import { ImPriceTags } from "react-icons/im";

const adminLinks = [
  {
    name: "Dashboard",
    icon: <MdDashboard className="mx-2 text-warning"/>,
    to: "/admin/dashboard"
  },
  {
    name: "Categories",
    icon: <RiListRadio className="mx-2 text-warning"/>,
    to: "/admin/categories"
  },
  {
    name: "Queries",
    icon: <IoMdList className="mx-2 text-warning"/>,
    to: "/admin/queries"
  },
  {
    name: "Users",
    icon: <IoPersonSharp className="mx-2 text-warning"/>,
    to: "/admin/users"
  },
  {
    name: "Write Blog",
    icon: <ImBlog className="mx-2 text-warning"/>,
    to: "/admin/blog"
  },
  {
    name: "Trial Plans",
    icon: <ImPriceTags className="mx-2 text-warning"/>,
    to: "/admin/trial-plans"
  },
  {
    name: "Pricing Plan",
    icon: <HiCurrencyDollar className="mx-2 text-warning"/>,
    to: "/admin/pricing-plans"
  },
  {
    name: "Buttons",
    icon: <IoMdRadioButtonOn className="mx-2 text-warning"/>,
    to: "/admin/buttons"
  },
  {
    name: "Settings",
    icon: <IoSettings className="mx-2 text-warning"/>,
    to: "/admin/settings"
  },
]

const UserLayout = () => {
  // Pre Made for Dealing with Side bar
  // Removed the use of Pre Maded Thing
  const { chats, setChats, showChat, setShowChat } = useContext(ChatsContext);
  // Self Made for dealing with side bar 
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [sideBarIsOpen, setSideBarIsOpen] = useState(false)
  const [hasSideBar, setHasSideBar] = useState(true);

  const handleExpandedSideBar = () => {
    if(sideBarIsOpen){
      setHasSideBar(true);
    }else {
    setHasSideBar(false);
    }
    setSideBarIsOpen(!sideBarIsOpen)
  } 


  const location = useLocation();

  const deleteChat = (chatId) => {
    axios.get(`${Helpers.apiUrl}chat/delete/${chatId}`, Helpers.authHeaders).then((response) => {
      Helpers.toast("success", response.data.message);
      setChats(response.data.chats);
    });
  };

  // toggle function
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [showToggleButton, setShowToggleButton] = useState(false);
  const handleResize = () => {
    setShowToggleButton(window.innerWidth <= 768); // Set breakpoint as needed
  };

  useEffect(() => {
    Helpers.toggleCSS();
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [location.pathname]);

  const navigate = useNavigate();

  const gotoChat = (url) => {
    setShowChat(true);
    navigate(url);
  };

  const logout = () => {
    localStorage.clear();

    window.location.href = "/auth/login";
    Helpers.toast("success", "Logged out successfully");
  };

  const isLoggedIn = localStorage.getItem("token");

  
  useEffect(() => {
    if(!(innerWidth > 500)){
      setHasSideBar(false)
      setSideBarIsOpen(true)
      console.log(innerWidth);
    }
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };
    handleResize();
  }, []);

  useEffect(() => {
    Helpers.toggleCSS();
  }, [location.pathname]);

  return (
    <div className="tyn-root"  style={{ background: "#0C1B44" }}>
      <nav className={` ${(sideBarIsOpen && (innerWidth < 500)) ? "tyn-appbar": ""} `}  style={{ background: "#0C1B44" }}>
        <div className="tyn-appbar-wrap">
          <div className="tyn-appbar-logo" style={{ position: "relative" }}>
            <Link className="tyn-logo" to={"/"}>
              <img src="/app/logo-duo.png" alt="logo" />
            </Link>
          </div>
          <div
            onClick={handleExpandedSideBar}
            className="nav-icon-container pointer"
          >
            {sideBarIsOpen ?
            <FaLongArrowAltRight className="nav-icon"/>
            // <i className="fa-duotone fa-arrow-right nav-icon"></i>
            :
            <FaLongArrowAltLeft className="nav-icon"/>
            // <i className="fa-duotone fa-arrow-left nav-icon"></i>
            }
          </div>
        </div>
      </nav>
      {/* ${showChat ? 'w0' : 'has-aside-base'} */}
      <div
        className={`tyn-content tyn-content-full-height tyn-chatbot tyn-chatbot-page ${hasSideBar ? "has-aside-base": ""}`}
        style={hasSideBar ? { background: "#0C1B44", overflowX: "hidden" }: {backgroundColor: "white", overflowX: "hidden"}} >
        <div className={`${hasSideBar ? "tyn-aside tyn-aside-base" : "d-none bg-white"}`}  style={{ background: "#0C1B44" }}>
          <div className="tyn-aside-body" data-simplebar   style={{ background: "#0C1B44" }}>
            <ul className="tyn-aside-list">
            {adminLinks.map((link, index)=> 
              <li
                key={index}
                className="tyn-aside-item tyn-aside-item-bubbly js-toggle-main chat-item my-2"
              >
                <Link to={link.to} className="content white">
                <div className="tyn-media-group">
                  <div
                    className="tyn-media-col pointer"
                  >
                    <div className="d-flex justify-content-start align-items-center fw-bold fs-4">
                    {link.icon} 
                    {link.name}
                    </div>
                  </div>
                </div>
                </Link>
              </li>
            )}
            </ul>
          </div>

          <div className="tyn-aside-foot">
            <div className="w-100">
              <div className="row gx-3">
                <div className="col-12">
                    <button
                      className="btn btn-dark btn-md tyn-size-md-lg w-100 flex-column"
                      onClick={logout}
                      style={{  background : "#c48219" }}
                      
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-box-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 12.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zM.146 8.354a.5.5 0 1 1 .708 0L4 11.5V8a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1H4.707l-3.146 3.146a.5.5 0 1 1-.708-.708L4.293 8.5H.5a.5.5 0 0 1-.5-.5z"
                        />
                        <path
                          fillRule="evenodd"
                          d="M13.5 3A1.5 1.5 0 0 1 15 4.5v7a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 3 11.5v-7A1.5 1.5 0 0 1 4.5 3h9zM4 4.5v7a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"
                        />
                      </svg>
                      <span
                        className="small text-nowrap mt-n1"
                        style={{ color: "black"  }}
                  
                      >
                        Logout
                      </span>
                    </button>
                </div>
              </div>
            </div>
            </div>

        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default UserLayout;
