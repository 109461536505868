import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import "./App.css";
import "./Color.css";
import Layout from "./Screens/Layout";
import Home from "./Screens/Home";
import Plans from "./Screens/Plans";
import UserLayout from "./Screens/User/Layout";
import UserDashboard from "./Screens/User/Dashboard";
import CategoryQuestion from "./Screens/User/CategoryQuestion";
import { ChatsProvider } from "./Contexts/ChatsContext";
import Chatbot from "./Screens/User/Chatbot";
import Login from "./Screens/Auth/Login";
import Register from "./Screens/Auth/Register";
import Payments from "./Screens/Auth/Payments";
import Features from "./Screens/Features";
import About from "./Screens/About";
import Contact from "./Screens/Contact";
import Donation from "./Screens/Donation";
import Subscribe from "./Screens/Auth/Subscribe";
import FormComponent from "./Screens/Auth/Form";
import TrialLogin from './Screens/Auth/TrialLogin';
import AdminLayout from './Screens/Admin/AdminLayout';
import AdminCategories from './Screens/Admin/Screens/Categories';
import Query from './Screens/Admin/Screens/Query';
import TrialPlan from './Screens/Admin/Screens/TrialPlan'
import Buttons from './Screens/Admin/Screens/Buttons';
import NavbarProvider from "./Contexts/NavbarProvider";
import Helpers from "./Config/Helpers";
import AdminDashboard from './Screens/Admin/AdminDashboard'
import AdminPricing from './Screens/Admin/Screens/Pricing'
import BlogEditor from './Screens/Admin/Screens/BlogEditor'
// import TrialModelForm from './Screens/User/MockupTrialForm';
import UserForm from "./Screens/User/UserForm";
import PricingPlan from "./Screens/User/PricingPlan";
import Chats from "./Screens/User/Chats";
import CommingSoon from "./Screens/User/CommingSoon"
import Teams from "./Screens/User/Teams"
import MockupTrial from "./Screens/User/MockupTrial";
import Users from "./Screens/Admin/Screens/Users";
import FreePlan from "./Screens/Admin/Screens/FreePlan";
import Settings from "./Screens/Admin/Screens/Settings";
import Blogs from "./Screens/Blogs";
import Blog from "./Screens/Blog";
import Test from "./Screens/Test";
import ForgotPassword from './Screens/Auth/ForgotPassword';
import VerifyForgotPassword from './Screens/Auth/VerifyForgot';
import RecoverPassword from './Screens/Auth/RecoverPassword';
import Verify from './Screens/Auth/Verify';

// const stripePromise = loadStripe(
//   "pk_live_51OX45lA0UPMTlR8lm8wjkkpayAehjqSdXShjlBbRS0yHzqBdBVke9JvrlK2e1Fu2lZdjMTOjRVNEjxhGz4ZcI7XN00Py9PhLOF"
// );
const stripePromise = loadStripe(
  "pk_test_51Of0LpKDP52BYTYxWwUCzLvfCo67FANYIyavru2i87PwCdqh2t2phT5SVLf4SxgPWIkSs9KkeVgwa8sERf1vxXDG00YO5EaLO8"
);

function App() {
  const token = localStorage.getItem("token");
  const Auth = ({children, isAuth = true, isAdmin = false}) => {
  let user = Helpers.getItem("user", true);
  let token = Helpers.getItem("token");
  let loginTime = Helpers.getItem("loginTimestamp");
  let currentTime = new Date().getTime();
  let minutesPassed = Math.floor((currentTime - loginTime) / (1000 * 60));

  // Check for session expiration
  if (loginTime && minutesPassed > 120) {
    localStorage.clear();
    Helpers.toast("error", "Session expired. Login again to continue");
    return <Navigate to="/auth/login" />;
  } 
  // For protected routes
  else if (isAuth) {
    if (!user || !token) {
      Helpers.toast("error", "Please login to continue");
      return <Navigate to="/auth/login" />;
    }

    // Ensure only admins can access admin routes
    if (isAdmin && user.user_type !==  1) {
      Helpers.toast("error", "Access denied. Only admin allowed.");
      return <Navigate to="/user/dashboard" />;
    }

    // Ensure admins cannot access user routes
    if (!isAdmin && user.user_type === 1) {
      Helpers.toast("error", "Access denied. Admins cannot access user routes.");
      return <Navigate to="/admin/dashboard" />;
    }

    return children;
  } 
  // For non-protected routes like /login
  else {
    if (user && token) {
      if (user.user_type === 1) {
        return <Navigate to="/admin/dashboard" />;
      } else {
        return <Navigate to="/user/dashboard" />;
      }
    }
    return children;
  }
}

  return (
    <ChatsProvider>
      <NavbarProvider>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <Routes>
            <Route path="/admin" element={<Auth isAdmin={true}><AdminLayout /></Auth>}>
              <Route path="/admin/dashboard" element={<Auth isAdmin={true}><AdminDashboard /></Auth>}></Route>
              <Route path="/admin/blog" element={<Auth isAdmin={true}><BlogEditor /></Auth>}></Route>
              <Route path="/admin/pricing-plans" element={<Auth isAdmin={true}><AdminPricing /></Auth>}></Route>
              <Route path="/admin/trial-plans" element={<Auth isAdmin={true}><TrialPlan /></Auth>}></Route>
              <Route path="/admin/categories" element={<Auth isAdmin={true}><AdminCategories /></Auth>}></Route>
              <Route path="/admin/queries" element={<Auth isAdmin={true}><Query /></Auth>}></Route>
              <Route path="/admin/free-plan" element={<Auth isAdmin={true}><FreePlan /></Auth>}></Route>
              <Route path="/admin/buttons" element={<Auth isAdmin={true}><Buttons /></Auth>}></Route>
              <Route path='/admin/users' element={<Auth isAdmin={true}><Users></Users></Auth>}></Route>
              <Route path='/admin/settings' element={<Auth isAdmin={true}><Settings></Settings></Auth>}></Route>
                  
            </Route>
              <Route path="/test" element={<Test />}></Route>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/Screen/Pricing" element={<Plans />} />
              <Route path="/Screen/Features" element={<Features />} />
              <Route path="/Screen/About" element={<About />} />
              <Route path="/Screen/Contact" element={<Contact/>} />
              <Route path="/Screen/Blog/:id" element={<Blog/>} />
              <Route path="/Screen/Blogs" element={<Blogs/>} />
              <Route path="/Screen/Donation" element={<Donation />} />
            </Route>
            <Route path="/Screen/Form" element={<FormComponent />} />
            <Route path="/auth/login" element={<Login />} />
            <Route path='/auth/verify-email' element={<Auth isAuth={false}><Verify /></Auth>} />
            <Route path='/auth/forgot-password' element={<Auth isAuth={false}><ForgotPassword /></Auth>} />
            <Route path='/auth/verify-email-password' element={<Auth isAuth={false}><VerifyForgotPassword /></Auth>} />
            <Route path='/auth/recover-password' element={<Auth isAuth={false}><RecoverPassword /></Auth>} />
            <Route path="/auth/Subscription/:id" element={<TrialLogin />} />
            <Route path="/auth/Subscribe/:id" element={<Auth isAdmin={false}><Subscribe /></Auth>} />
            {token ? (
              <Route
                path="/auth/register"
                element={<Navigate to="/user/dashboard" />}
              />
            ) : (
              <Route path="/auth/register" element={<Register />} />
            )}

            {token ? (
              <Route
                path="/auth/register/team/:id"
                element={<Navigate to="/user/dashboard" />}
              />
            ) : (
              <Route path="/auth/register/team/:id" element={<Register />} />
            )}

            <Route path="/auth/Payments" element={<Payments />} />
            {/* <Route 
              path="/screen/mockup/info"
              element={<TrialModelForm/>}
              /> */}
            <Route path="/user" element={<Auth isAdmin={false}><UserLayout /></Auth>}>
              <Route path="/user/dashboard" element={<Auth isAdmin={false}><UserDashboard /></Auth>} />
              <Route path="/user/category/:id" element={<Auth isAdmin={false}><CategoryQuestion/></Auth>} />
              <Route path="/user/form" element={<Auth isAdmin={false}><UserForm/></Auth>} />
              <Route path="/user/pricing" element={<Auth isAdmin={false}><PricingPlan /></Auth>}></Route>
              <Route path="/user/chat-history" element={<Auth isAdmin={false}><Chats /></Auth>}></Route>
              <Route path="/user/coming-soon" element={<Auth isAdmin={false}><CommingSoon/></Auth>}></Route>
              <Route path="/user/teams" element={<Auth isAdmin={false}><Teams/></Auth>}></Route>
              <Route path="/user/mockup-trial/:trial_id" element={<Auth isAdmin={false}><MockupTrial/></Auth>}></Route>
              <Route
                path="/user/chat/:chat_id"
                element={
                  <Elements stripe={stripePromise}>
                    <Chatbot />
                  </Elements>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </Elements>
      </NavbarProvider>
    </ChatsProvider>
  );
}

export default App;
