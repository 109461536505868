import { useContext, useState, useEffect } from "react";
import { ChatsContext } from "../../Contexts/ChatsContext";
import { TbAbc } from "react-icons/tb";
import axios from "axios";
import Helpers from "../../Config/Helpers";
import PageLoader from "../../Components/Loader/PageLoader";
import { Link } from "react-router-dom";
import { IoMdCheckmark } from "react-icons/io";
import { Modal, Button } from 'react-bootstrap';

const PricingPlan = () => {
    const { setChats, showChat } = useContext(ChatsContext);
    const [plans, setPlans] = useState([]);
    const token = localStorage.getItem('')

    const getPricing = () => {
        axios.get(`${Helpers.apiUrl}pricing/all`,Helpers.authHeaders).then(response => {
            setPlans(response.data.plans);
            console.log(response.data)
            Helpers.toast(response.data.message);
        }).catch(err => {
            Helpers.toast('error', err.message);
        })
    }

    useEffect(() => {
        getPricing();
    }, [])
    return (
        <div
            className={`tyn-main ${showChat ? "main-shown" : ""}`}
            id="tynMain"
            style={{ overflow: "auto", backgroundColor: "white" }}
        >
            {/* <div className="tyn-section"> */}
            {!(plans.length > 0) ? <PageLoader></PageLoader> : (<div className="container-fluid">

                <div className="tyn-text-block m-2">
                    <h1 className="mt-3 black">Pricing Plans</h1>
                    <p className="white-sub text-muted">Subscribe to get amazing Rewards</p>
                </div>

                <div className="row m-2 col-md-12">

                    {/* Card */}
                    {plans.map(pln => {
                        if(pln.is_trial == 0){
                        return (
                            <div className={`col-md-4 
                            ${(pln.monthly_price == "0") ? "d-none" : ""} 
                            ${(pln.is_trial === 1) ? 'd-none' : ""}`}>
                                <div className="Namecard p-1 m-1 ml-0 shadow" 
                                style={{
                                    height: "70vh", 
                                    overflowY: "scroll", 
                                    WebkitOverflowScrolling: 'touch', 
                                    msOverflowStyle: 'none', 
                                    scrollbarWidth: 'none'
                                }}
                                >
                                    <div className="card-body">
                                        <p>{pln.plan_type}</p>
                                        <h3 
                                        className="sub-heading fw-semibold">
                                        {pln.name}</h3>
                                        <h1 
                                        className="heading fw-bolder fs-1">
                                            ${pln.monthly_price}
                                            <span className="caption-text fs-5 fw-normal">
                                                 / month
                                            </span>
                                        </h1>
                                        <Link
                                            to={`/auth/Subscribe/${pln.id}`}
                                            className={`btn btn-light btn-lg w-100 my-2`}
                                        >
                                            Upgrade
                                        </Link>
                                        <div className="d-flex flex-column my-2">
                                            <div className="d-flex flex-row align-items-center my-2">
                                            </div>
                                            {pln.plan_features.map((e, index) => {
                                                return (
                                                    <>
                                                        <p className="m-1">
                                                            <IoMdCheckmark className="text-prim my-1 fw-semibold" />
                                                            {e.feature}
                                                        </p>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    })
                    }

                </div>
                {/* </div> */}
            </div>)}
        </div>
    )
}

export default PricingPlan;