import React, { Component } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="banner-style-six">
          <div className="pattern-layer">
            <div
              className="pattern-1"
              style={{
                backgroundImage: "url(assets/images/shape/shape-49.png)",
              }}
            ></div>
            <div
              className="pattern-2"
              style={{
                backgroundImage: "url(assets/images/shape/shape-50.png)",
              }}
            ></div>
          </div>
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content-box ml_30">
                  <h2 className="heading">About Us</h2>
                  <p>
                  At Build Champions Non-Profit, our mission is crystal clear: we are dedicated to empowering individuals and underserved communities by transforming justice from a privilege into a fundamental right.<br/><br/>

                  Our motto, <strong>'Justice should not be a privilege but a fundamental right,'</strong> underscores our unwavering commitment to democratizing access to justice. We achieve this by providing cutting-edge AI legal services to public defenders, legal aid clinics, law students, unrepresented individuals, and small law firms.<br/><br/>
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                <div className="image-box ml_40 mr_50">
                  <div className="image-shape">
                    <div
                      className="shape-1"
                      style={{
                        backgroundImage:
                          "url(/assets/images/shape/shape-47.png)",
                      }}
                    ></div>
                    <div
                      className="shape-2 rotate-me"
                      style={{
                        backgroundImage:
                          "url(/assets/images/shape/shape-48.png)",
                      }}
                    ></div>
                  </div>
                  <figure className="image">
                    <img src="/app/about.png" alt="" />
                  </figure>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 content-column">
                <div className="content-box ml_30">
                  <p className="pt-5">
                    
                  As a 501(c)(3) Non-Profit Organization, we operate with utmost transparency, accountability, and a steadfast focus on our mission. We acknowledge the pivotal role that public defenders and legal aid clinics play in ensuring equitable access to justice. We are equally dedicated to supporting law students, unrepresented individuals, and small law firms, recognizing their integral role in our community.<br/><br/>

                  With your support, we're on a mission to make justice a fundamental right for everyone. Together, we're reshaping the world so that 'Justice should not be a privilege but a fundamental right' becomes a reality for all."<br/><br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="clients-section alternat-2 pt_40 pb_80 centred mt-5">
          <div className="auto-container">
            <div className="inner-container">
              <h3>
                Drafting legal documents can be a time-consuming and complex
                task.
              </h3>
              <p>
                This service saves you time and effort, allowing you to focus on
                the strategic aspects of your legal endeavors.
              </p>
            </div>
          </div>
        </section>

        <section className="about-style-four  pb_150">
          <div className="auto-container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                <div className="content_block_five">
                  <div className="content-box">
                    <div
                      className="shape"
                      style={{
                        backgroundImage:
                          "url(/assets/images/shape/shape-52.png)",
                      }}
                    ></div>

                    <figure className="image-box ">
                      <img src="/app/mock-trial.png" alt="" />
                    </figure>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-6 col-md-12 col-sm-12 content-column"
                id="about"
              >
                <div className="content_block_six">
                  <div className="content-box ml_40">
                    <div data-animation-box className="sec-title mb_30">
                      <span
                        data-animation-text
                        className="sub-title-three overlay-anim-white-bg"
                        data-animation="overlay-animation"
                      >
                        Mission Statement
                      </span>
                    </div>
                    <div className="inner-box mb_40">
                      <div className="single-item mb_30">
                        <h3>Mission Statement</h3>
                        <p>
                          
                  At Build Champions Non-Profit, our mission is to empower individuals and communities by making justice accessible to all. We provide cutting-edge AI legal services to public defenders, legal aid clinics, law students, unrepresented individuals, and small law firms. Our goal is to ensure that justice is not a privilege but a fundamental right for every person."
                        </p>
                        <ul>
                          <li>
                            1. Extensive Legal Database: Your Gateway to Legal Insight
                          </li>
                          <li>
                            2. Effortless Document Drafting: Simplify Your Legal Process with AI
                          </li>
                          <li>
                            3. Legal Consultancy: Instant Legal Advice, Anytime
                          </li>
                          <li>
                            4. Advanced Voice Recognition: Interact Naturally with Our AI Lawyer
                          </li>
                          <li>
                            5. Legal Mock Trials: Practice, Perfect, Prevail
                          </li>
                        </ul>
                        <div className="btn-box mt-5">
              <Link to="/user/dashboard" className="theme-btn btn-one mr_20">
                Try AI Consultation
              </Link>
             
            </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Additional List */}
          </div>
        </section>


    <section className="banner-style-six" style={{padding: "100px 0px 150px 0px"}}>
      <div className="pattern-layer">
        <div className="auto-container">
          <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 col-sm-12 content-column">
                  <div className="content-box ml_30">
                  <h3 className="heading">Legal Master AI:</h3>
                  <p>
                    Legal Master AI is at the heart of our mission, offering a range of essential services:
                  </p>

                  <h4 className="sub-heading">1. Extensive Legal Database: Your Gateway to Legal Insight</h4>
                  <p>
                  Legal Master AI offers a vast and up-to-date legal database that includes Supreme Court judgments, Federal Rules of Civil Procedure, and specific rules for civil and criminal procedures in all 50 states and Washington D.C. This database serves as a critical resource for research and informed decision-making, offering users access to a broad range of legal information. It's especially beneficial for legal professionals, researchers, and students who require detailed and accurate legal data.
                  </p>
                  
                  <h4 className="sub-heading">2. Effortless Document Drafting: Simplify Your Legal Process with AI</h4>
                  <p>
                  Drafting legal documents can be a time-consuming and complex task. Legal Master AI streamlines this process effortlessly with its AI-powered document drafting tool. It ensures that documents are not only accurately drafted but also customized to your specific needs, compliant with the latest regulations, and ready for any legal scrutiny. This service saves you time and effort, allowing you to focus on the strategic aspects of your legal endeavors.
                  </p>
                  
                  <h4 className="sub-heading">3. Legal Consultancy: Instant Legal Advice, Anytime</h4>
                  <p>
                  Accessing legal guidance has never been easier. Legal Master AI's Legal Consultancy service provides immediate, reliable, and personalized legal advice to help you navigate any legal query or dilemma. Leveraging vast databases of legal knowledge and current case law, our AI Lawyer offers comprehensive legal counsel on-demand. Say goodbye to waiting for appointments, and get expert legal advice instantly, 24/7, to make informed decisions quickly and confidently.
                  </p>
                  
                  <h4 className="sub-heading">4. Advanced Voice Recognition: Interact Naturally with Our AI Lawyer</h4>
                  <p>
                  Engage with our AI Lawyer as naturally as you would with a human attorney. Legal Master AI's advanced voice recognition feature understands and processes your spoken queries, delivering accurate legal insights and assistance in response. Powered by sophisticated Natural Language Processing (NLP) algorithms, it comprehensively understands context, nuances, and legal terminology from your voice commands. This hands-free support ensures your legal needs are heard and addressed promptly and precisely.
                  </p>
                  
                  <h4 className="sub-heading">5. Legal Mock Trials: Practice, Perfect, Prevail</h4>
                  <p>
                  Sharpen your legal skills and strategy with Legal Master AI's Legal Mock Trials feature. Whether you're an attorney preparing for a case, a law student honing your advocacy, or a business professional seeking insight into litigation, our AI Lawyer provides a realistic and invaluable trial simulation experience. Step into the courtroom, present your case, and receive feedback and analysis to fine-tune your legal prowess. This immersive experience helps you practice, perfect, and ultimately prevail in the legal arena.
                  </p>
                  <p>
                  These five services collectively demonstrate Legal Master AI's commitment to providing accessible, efficient, and comprehensive legal assistance while advancing the cause of justice through cutting-edge AI technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      </div>
    );
  }
}
