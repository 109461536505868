import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../../Config/Helpers";

const Verify = () => {
    const navigate = useNavigate();

    const defaultUser = {
        code: "",
        user_id: "",
    }

    const [user, setUser] = useState(defaultUser);
    const [isLoading, setIsLoading] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [errors, setErrors] = useState({});

    const handleVerification = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}auth/verify-email`, user).then(response => {
            Helpers.toast("success", response.data.message);
            Helpers.setItem("user", response.data.user, true);
            Helpers.setItem("token", response.data.token);
            window.location.href = "/user/dashboard";
            setIsLoading(false);
        }).catch(error => {
            setErrors(error.response.data.errors || {});
            Helpers.toast("error", error.response.data.message);
            setIsLoading(false);
        });
    }

    const resendEmail = (e) => {
        e.preventDefault();
        setSendingEmail(true);
        axios.post(`${Helpers.apiUrl}auth/resend-email`, {user_id: user.user_id}).then(response => {
            Helpers.toast("success", response.data.message);
            setSendingEmail(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setSendingEmail(false);
        });
    }

    useEffect(() => {
        const checkUserId = localStorage.getItem("user_id");
        if(checkUserId){
            setUser({...user, user_id: checkUserId});
        }else{
            navigate("/register");
        }
    }, []);
    return (
        <div className="tyn-root " style={{ background: '#F8F8F8' }}>
    
            <div className="tyn-content tyn-auth tyn-auth-centered d-flex justify-content-center align-items-center"  style={{minHeight: '100vh', overflowY: "hidden"}}>
            <div className="container">
                <div className="row justify-content-center" >
                <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9"  style={{ background: 'White', padding: '30px', borderRadius: '10px' }}>
                    <div className="my-3 text-center">
                    <img src="	https://legalmasterai.com/app/Legaldrafting.png" className="w200" />
                    </div>
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-12 col-xl-10 col-xxl-9">
                            <h6 className="overline-title color-primary">We've sent you an OTP to your provided email address. Check your inbox!</h6>
                            <p className="title">Verify Your Email Address</p>
                        </div>
                    </div>
                    <form onSubmit={handleVerification}>
                        <div className="form-group" >
                            <label
                                htmlFor="email-address"
                                className="form-label white-sub"
                            >
                                Verification Code
                            </label>
                            <div className="form-control-wrap">
                                <input type="text" className="form-control form-control-lg" value={user.code} maxLength={6} onChange={e => setUser({...user, code: e.target.value})} placeholder="Enter Verification Code" />
                                <small className="text-danger">{ errors.code ? errors.code[0] : '' }</small>
                            </div>
                        </div>
                        <div className="form-group pt-2"><button type="submit" className="btn w-100" style={{ background: "#0C1B44" , color : 'white' }}  disabled={isLoading} onClick={handleVerification}>{isLoading ? 'Verifying...' : 'Verify Email'}</button>
                        </div>
                    </form>
                </div>
                </div>
                <div className="text-center mt-4">
                    <p className="text-center color-primary mt-4">Didn't received code? <Link onClick={resendEmail}>{sendingEmail ? 'Resending....' : 'Resend'}</Link></p>
                </div>
            </div>
            </div>
        </div>
        );
}

export default Verify;