import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Helpers from '../../../Config/Helpers';
import PageLoader from '../../../Components/Loader/PageLoader';

export default function Settings() {
    const [api, setApi] = useState('')
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});

    const getKey = () =>{
        setLoading(true)
        axios.get(`${Helpers.apiUrl}open-api/get`, Helpers.authHeaders).then((response)=>{
            setApi(response.data.api[0].api);
            setLoading(false);
            Helpers.toast('success', response.data.message);
        }).catch((error)=>{
            Helpers.toast('error', error);
            setErrors({error});
            setLoading(false);
        })
    }

    const saveKey = () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('api', api);
        axios.post(`${Helpers.apiUrl}open-api/save`, formData, Helpers.authHeaders).then((response)=>{
            setApi(response.data.api.api);
            setLoading(false);
            Helpers.toast('success', response.data.message);
        }).catch((error)=>{
            Helpers.toast('error', error);
            setErrors({error});
            setLoading(false);
        })
    }

    useEffect(()=>{
        getKey();
    },[])

  return (
    <div className="nk-content container-xl bg-white">
    <div className="container-xl">


    <div className="nk-content-inner">
            <div className="nk-content-body">

                {(loading) ? <PageLoader></PageLoader> : (<div className="nk-block-head nk-page-head">
                    <div className="nk-block-head-between">
                        <div className="nk-block-head-content">
                            <h2 className="display-6">Settings</h2>
                            <p>Manage the Settings of the App</p>
                        </div>
                        <div className="d-flex justify-content-end m-2">

                        <div className="nk-block col-md-12">

                          <div className="card shadown-none">
                            <div className="card-body">
                                <div className="row g-gs">
                                    <div className="form-group">
                                        <label className="form-label">ChatGPT API:</label>
                                        <div className="form-control-wrap">
                                            <input type="text" value={api} onChange={e => setApi(e.target.value)} className="form-control" placeholder="Enter API for ChatGPT" />
                                            <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            <button className='btn btn-prim m-2' onClick={saveKey}>Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                </div>)}

            </div>
        </div>


    </div>
    </div>
  )
}
