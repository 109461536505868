import React from 'react'
import PageLoader from '../../../Components/Loader/PageLoader'
import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import Select from 'react-select';
import { FaPlusCircle ,FaRegEdit, FaCheckCircle } from "react-icons/fa";

export default function FreePlan() {
    const defaultGuestLimit = {
        name: "",
        no_of_words: 0,
        activation_status: 0
    }
    const [guestLimit, setGuestLimit] = useState(defaultGuestLimit);
    const [guestLimits, setGuestLimits] = useState([]);
    const [selectedSetting, setSelectedSetting] = useState(0);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [IsDeleting, setIsDeleting] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const getSettings = () => {
        axios.get(`${Helpers.apiUrl}free-plan/all`, Helpers.authHeaders).then(response => {
            setGuestLimits(response.data);
        });
    }

    const saveSetting = (e) => {
        e.preventDefault();
        setIsLoading(true);
        console.log(guestLimit)
        axios.post(`${Helpers.apiUrl}free-plan/save`, guestLimit, Helpers.authHeaders).then(response => {
            getSettings();
            Helpers.toast("success", response.data.message);
            hideShowForm();
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        })
    }

    const handelEdit = guestLimit => {
        if(guestLimit.activation_status == 1){
            console.log("activated")
        }
        setGuestLimit(guestLimit);
        setShowForm(true);
    }


    const handleDelete = (id) => {
        setIsDeleting(true);
        axios.get(`${Helpers.apiUrl}free-plan/delete/${id}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getSettings();
            setSelectedSetting(0);
            setIsDeleting(false);
        }).catch(e=>{
            Helpers.toast("error", e.response.data.message)
        });
    }

    const hideShowForm = () => {
        setGuestLimit(defaultGuestLimit);
        setErrors({});
        setShowForm(false);
    }

    useEffect(() => {
        getSettings();
        return () => {
            getSettings();
        };
    }, []);


    return (
        <div className="nk-content container-xl bg-white">
        <div className="container-xl">
                {guestLimit > 0 ? <PageLoader/> :  (<div className="nk-content-inner">
                <div className="nk-content-body">
                    <div className="nk-block-head nk-page-head">
                        <div className="nk-block-head-between d-flex flex-row justify-content-between align-items-center">
                            <div className="nk-block-head-content">
                                <h2 className="display-6">Free Plan</h2>
                                <p>Manage Guest's Word Limit</p>
                            </div>
                            <div className="d-flex justify-content-end m-2">
                            <button className="btn btn-prim" 
                            onClick={() => setShowForm(true)}
                            >
                            <FaPlusCircle />
                             Add Query</button>
                            </div>
                        </div>
                    </div>
                    {showForm && <div className="nk-block">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-head-content"><h3 className="nk-block-title">Create New Guest Limit</h3></div>
                            </div>
                            <div className="card shadown-none">
                                <div className="card-body">
                                    <div className="row g-3 gx-gs">
                                        <form onSubmit={saveSetting}>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Name</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" value={guestLimit.name} onChange={e => setGuestLimit({...guestLimit, name: e.target.value})} className="form-control" placeholder="Enter Limit name" />
                                                        <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">No Of Words</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" value={guestLimit.no_of_words} onChange={e => setGuestLimit({...guestLimit, no_of_words: e.target.value})} className="form-control" placeholder="Enter Limit of Words" />
                                                        <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-check my-4">
                                            <input 
                                                className="form-check-input" 
                                                type="checkbox" 
                                                checked={guestLimit.activation_status == "1"} // Check if activation_status is 1
                                                id="flexCheckDefault" 
                                                onChange={(e) => {
                                                    const newValue = e.target.checked ? 1 : 0; // If checked, set to 1, otherwise set to 0
                                                    setGuestLimit({ ...guestLimit, activation_status: newValue });
                                                }}
                                                />
                                                    <label className="form-check-label" for="flexCheckDefault">
                                                        Activate this Limit
                                                    </label>
                                            </div>

                                        </form>
                                        <div className="col-md-12 d-flex justify-content-end">
                                            <button className="btn btn-prim m-1" onClick={saveSetting} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save Limit'}</button>
                                            <button className="btn btn-outline-second ml10 m-1" onClick={hideShowForm} disabled={isLoading}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {(!showForm) && <div className="nk-block">
                            <div className="mt-xl-5">
                                <div className="row g-0">
                                    {guestLimits.map((pln, id) => {
                                        return (
                                            <div key={id} className="col-xl-4">
                                                <div className={pln.activated_status === "1" ? "card pricing  border-warning rounded-start" : "card pricing bg-white rounded-start"}>
                                                    <div className={`card shadown-none ${pln.activated_status === "1" ? "border-warning": ""}`}>
                                                        <div className="card-body">
                                                            <h2 className="mb-3">{ pln.name }</h2>
                                                            <div className="pricing-price-wrap">
                                                                <div className={`pricing-price pricing-toggle-content monthly { activePlan === 'monthly' ? 'active' : '' }`}>
                                                                </div>
                                                            </div>
                                                            <ul className="pricing-features d-flex flex-row align-items-center">
                                                            <FaCheckCircle className='text-primary' /><li className='mx-1'> No of Words:  <span className='mx-1'>{ pln.no_of_words }</span></li>
                                                            </ul>
                                                            <div className="mb-2 mx-auto mt-5">
                                                                <button onClick={() => handelEdit(pln)} className="btn w-100 btn-prim mb-1" disabled={isLoading}>Edit Plan</button>
                                                                {(guestLimits.length > 1 ) && <button onClick={() => handleDelete(pln.id)} className="btn btn-outline-second w-100 mb-1"  disabled={isLoading}>Delete</button>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>}
                </div>
                </div>)}
            </div>
        </div>
    )
}
