import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import axios from "axios";
import Helpers from '../Config/Helpers';

export default function Features() {
    const [activeTab, setActiveTab] = useState('#tab-1');
    const [buttons, setButtons] = useState([]);
      
    const getButtons = () => {
      axios.get(`${Helpers.apiUrl}button/all`, Helpers.authHeaders).then(response=>{
        setButtons(response.data.buttons);
      }).catch(error=>{
        Helpers.toast("error", error.response.message);
      })
    }

    useEffect(()=>{
      getButtons();
    },[])

    const handleTabClick = (tabId) => {
      setActiveTab(tabId);
    };
  return (
        <div className='pt-5'><div className='pt-5'>
           <section className="service-style-three pb_150">
          <div className="auto-container">
            <div data-animation-box className="sec-title mb_55 centred">
              <span
                data-animation-text
                className="sub-title-three overlay-anim-white-bg"
                data-animation="overlay-animation"
              >
                AI Lawyer
              </span>
              <h2 className='fw-bold'> AI SERVICES</h2>
            </div>
            <div className="tabs-box">
              <div className="tab-btn-box p_relative mb_60">
  
          <ul className="tab-btns tab-buttons clearfix">
            <li
              className={activeTab === '#tab-1' ? 'tab-btn active-btn' : 'tab-btn'}
              onClick={() => handleTabClick('#tab-1')}
              data-tab="#tab-1"
            >
              Legal Database
            </li>
            <li
              className={activeTab === '#tab-2' ? 'tab-btn active-btn' : 'tab-btn'}
              onClick={() => handleTabClick('#tab-2')}
              data-tab="#tab-2"
            >
              Legal Document
            </li>
            <li
              className={activeTab === '#tab-3' ? 'tab-btn active-btn' : 'tab-btn'}
              onClick={() => handleTabClick('#tab-3')}
              data-tab="#tab-3"
            >
              Legal Consultancy
            </li>
            <li
              className={activeTab === '#tab-4' ? 'tab-btn active-btn' : 'tab-btn'}
              onClick={() => handleTabClick('#tab-4')}
              data-tab="#tab-4"
            >
              Voice Recording
            </li>
            <li
              className={activeTab === '#tab-5' ? 'tab-btn active-btn' : 'tab-btn'}
              onClick={() => handleTabClick('#tab-5')}
              data-tab="#tab-5"
            >
              Legal Mock Trials
            </li>
          </ul>
  
              </div>
              <div className="tabs-content">
                <div className={`${activeTab === '#tab-1' ? 'tab active-tab': 'tab'}`} id="tab-1">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                      <div className="content_block_three">
                        <div className="content-box mr_40">
                          <h2 className='fw-semibold'>Legal Queries: Your Gateway to Legal Insight</h2>
                          <p>
                            Navigate the complexities of law with ease. Our
                            AI-driven platform offers instantaneous, reliable
                            answers to your legal questions, ensuring you're
                            always informed and prepared.
                          </p>
                          <ul className="list-style-two clearfix">
                            <li>
                              Access well-founded legal advice at the click of a
                              button, drawing from a vast repository of legal
                              knowledge.
                            </li>
                            <li>
                              Dive into our extensive database for detailed
                              insights into case laws, statutes, and legal
                              precedents.
                            </li>
                            <li>
                              Confidentiality is paramount. Your inquiries and the
                              information provided are protected with the highest
                              standards of privacy.
                            </li>
                            <div className="btn-box mb_40">
                              
                            {buttons.length > 0 ? buttons.map((btn)=>{
                            if(btn.name ==  "Legal Database Button"){
                              return(
                                <Link to={btn.button_link} className="theme-btn btn-one">
                                  {btn.button_text}
                                </Link>
                              );
                            }
                          })
                          : 
                          <Link to="/user/dashboard" className="theme-btn btn-one">
                            Get Started
                          </Link> }

                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <div className="image-box ml_40">
                        <figure className="image">
                          <img src="../app/legal-queries.png" alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${activeTab === '#tab-2' ? 'tab active-tab': 'tab'}`} id="tab-2">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                      <div className="content_block_three">
                        <div className="content-box mr_40">
                          <h2 className='fw-semibold'>Effortless Document Drafting</h2>
                          <p>
                            Simplify your legal drafting process with our
                            AI-powered tool. Experience the future of legal
                            documentation, where precision meets efficiency. Our
                            intelligent system not only drafts documents but also
                            ensures they are customized to your specific needs,
                            compliant with the latest regulations, and ready for
                            any legal scrutiny.
                          </p>
                          <ul className="list-style-two clearfix">
                            <li>
                              Our AI meticulously crafts documents that cater to
                              your individual legal requirements, ensuring every
                              term and clause is in your best interest.
                            </li>
                            <li>
                              Stay ahead of the legal curve with documents that
                              are automatically updated to reflect the latest laws
                              and regulations relevant to your case.
                            </li>
                            <li>
                              Transform hours of drafting into minutes with our
                              AI, freeing you to focus on the strategic aspects of
                              your legal endeavors.
                            </li>
                            <div className="btn-box mb_40">
                              
                            {buttons.length > 0 ? buttons.map((btn)=>{
                            if(btn.name ==  "Legal Document Button"){
                              return(
                                <Link to={btn.button_link} className="theme-btn btn-one">
                                  {btn.button_text}
                                </Link>
                              );
                            }
                          })
                          : 
                          <Link to="/user/dashboard" className="theme-btn btn-one">
                            Get Started
                          </Link> }

                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <div className="image-box ml_40">
                        <figure className="image">
                          <img src="../app/document-drafting.png" alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${activeTab === '#tab-3' ? 'tab active-tab': 'tab'}`} id="tab-3">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                      <div className="content_block_three">
                        <div className="content-box mr_40">
                          <h2 className='fw-semibold'>Instant Legal Advice, Anytime</h2>
                          <p>
                            Access real-time legal guidance without the wait. Our
                            AI Lawyer provides immediate, reliable, and
                            personalized legal advice to help you navigate through
                            any legal query or dilemma. Leveraging vast databases
                            of legal knowledge and current case law, our AI is
                            equipped to offer you comprehensive legal counsel
                            on-demand.
                          </p>
                          <ul className="list-style-two clearfix">
                            <li>
                              No more waiting for appointments. Get expert legal
                              advice instantly, 24/7, to make informed decisions
                              quickly and confidently.
                            </li>
                            <li>
                              Our AI taps into a broad spectrum of legal
                              precedents and regulations to deliver advice that
                              encompasses all angles of your legal situation.
                            </li>
                            <li>
                              The AI Lawyer tailors its counsel to your specific
                              circumstances, ensuring advice that's relevant to
                              your unique legal context.
                            </li>
                            <div className="btn-box mb_40">
                              
                            {buttons.length > 0 ? buttons.map((btn)=>{
                            if(btn.name ==  "Legal Consultancy Button"){
                              return(
                                <Link to={btn.button_link} className="theme-btn btn-one">
                                  {btn.button_text}
                                </Link>
                              );
                            }
                          })
                          : 
                          <Link to="/user/dashboard" className="theme-btn btn-one">
                            Get Started
                          </Link> }

                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <div className="image-box ml_40">
                        <figure className="image">
                          <img src="../app/legal-advice.png" alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${activeTab === '#tab-4' ? 'tab active-tab': 'tab'}`} id="tab-4">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                      <div className="content_block_three">
                        <div className="content-box mr_40">
                          <h2 className='fw-semibold'>
                            Speak Up, We're Listening - Advanced Voice Recognition
                          </h2>
                          <p>
                            Interact with our AI Lawyer as naturally as you would
                            with a human attorney. Our state-of-the-art voice
                            recognition feature understands and processes your
                            spoken queries, delivering accurate legal insights and
                            assistance in response. Whether you're on the go or in
                            the middle of a task, our hands-free support ensures
                            your legal needs are heard and addressed promptly and
                            precisely.
                          </p>
                          <ul className="list-style-two clearfix">
                            <li>
                              Powered by sophisticated NLP algorithms, our system
                              comprehensively understands context, nuances, and
                              legal terminology from your voice commands
                            </li>
                            <li>
                              Engage in fluid, dynamic conversations with our AI
                              Lawyer that understands follow-up questions and
                              complex legal jargon, ensuring a natural and
                              interactive experience
                            </li>
                            <li>
                              Use your voice to navigate through legal procedures,
                              draft documents, or get advice, making legal
                              assistance accessible anytime, without the need for
                              typing or clicking
                            </li>
                            <div className="btn-box mb_40">
                              
                            {buttons.length > 0 ? buttons.map((btn)=>{
                            if(btn.name ==  "Voice Recording Button"){
                              return(
                                <Link to={btn.button_link} className="theme-btn btn-one">
                                  {btn.button_text}
                                </Link>
                              );
                            }
                          })
                          : 
                          <Link to="/user/dashboard" className="theme-btn btn-one">
                            Get Started
                          </Link> }

                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <div className="image-box ml_40">
                        <figure className="image">
                          <img src="../app/voice.png" alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${activeTab === '#tab-5' ? 'tab active-tab': 'tab'}`} id="tab-5">
                  <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12 content-column">
                      <div className="content_block_three">
                        <div className="content-box mr_40">
                          <h2 className='fw-semibold'>Legal Mock Trials - Practice, Perfect, Prevail</h2>
                          <p>
                            Sharpen your legal skills and strategy with our
                            cutting-edge Legal Mock Trials feature. Whether you're
                            an attorney preparing for a case, a law student honing
                            your advocacy, or a business professional seeking
                            insight into litigation, our AI Lawyer provides a
                            realistic and invaluable trial simulation experience.
                            Step into the courtroom, present your case, and
                            receive feedback and analysis to fine-tune your legal
                            prowess
                          </p>
                          <ul className="list-style-two clearfix">
                            <li>
                              Immerse yourself in lifelike courtroom scenarios,
                              complete with judges, juries, witnesses, and
                              opposing counsel, to practice your case presentation
                              and argumentation
                            </li>
                            <li>
                              Receive instant, data-driven feedback on your trial
                              performance, including areas of improvement,
                              strengths, and suggested strategies for success
                            </li>
                            <li>
                              Customize mock trials to suit your specific legal
                              practice area or academic needs, ensuring targeted
                              skill development and enhancement of your legal
                              expertise.
                            </li>
                            <div className="btn-box mb_40">
                              
                            {buttons.length > 0 ? buttons.map((btn)=>{
                            if(btn.name ==  "Legal Mockup Trials Button"){
                              return(
                                <Link to={btn.button_link} className="theme-btn btn-one">
                                  {btn.button_text}
                                </Link>
                              );
                            }
                          })
                          : 
                          <Link to="/user/dashboard" className="theme-btn btn-one">
                            Get Started
                          </Link> }

                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 image-column">
                      <div className="image-box ml_40">
                        <figure className="image">
                          <img src="../app/mock-trial.png" alt="" />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
  
        <section className="clients-section alternat-2 pt_80 pb_80 centred">
          <div className="auto-container">
            <div className="inner-container">
              <h3>
                Trusted by Industry Leaders<span> Industry Leaders</span>
              </h3>
              <p>
                Join the ranks of top legal firms and businesses that leverage our
                AI Lawyer for efficient, accurate legal support.
              </p>
            </div>
          </div>
        </section>
     </div></div>
      )
}

