import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Helpers from "../../Config/Helpers";

const ForgotPassword = () => {

    const defaultUser = {
        email: "",
    }

    const navigate = useNavigate();

    const [user, setUser] = useState(defaultUser);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleForgotPassword = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}auth/forgot-password`, user).then(response => {
            Helpers.toast("success", response.data.message);
            Helpers.setItem("user_id", response.data.user_id);
            navigate('/auth/verify-email-password');
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        })
    }
    
return (
    <div className="tyn-root " style={{ background: '#F8F8F8' }}>

        <div className="tyn-content tyn-auth tyn-auth-centered d-flex justify-content-center align-items-center"  style={{minHeight: '100vh', overflowY: "hidden"}}>
        <div className="container">
            <div className="row justify-content-center" >
            <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9"  style={{ background: 'White', padding: '30px', borderRadius: '10px' }}>
                <div className="my-3 text-center">
                <img src="	https://legalmasterai.com/app/Legaldrafting.png" className="w200" />
                </div>
                <div className="row justify-content-center text-center">
                    <div className="col-lg-12 col-xl-10 col-xxl-9">
                        <h6 className="overline-title color-primary">Forgot Your Password?</h6>
                        <p className="title">Don't worry enter your email address to create a new password</p>
                    </div>
                </div>
                <form onSubmit={handleForgotPassword}>
                    <div className="form-group" >
                    <label
                        htmlFor="email-address"
                        className="form-label white-sub"
                    >
                        Email Address
                    </label>
                    <div className="form-control-wrap">
                        <input type="email" value={user.email} onChange={e => setUser({...user, email:e.target.value})} className="form-control form-control-lg" placeholder="Enter Email Address"  />
                        <small className="text-danger">
                        {errors.email ? errors.email[0] : ""}
                        </small>
                    </div>
                    </div>
    
                    <div className="form-group pt-2"><button type="submit" className="btn w-100" style={{ background: "#0C1B44" , color : 'white' }} disabled={isLoading} onClick={handleForgotPassword}>{isLoading ? 'Please wait...' : 'Verify Email Address'}</button></div>
                </form>
            </div>
            </div>
        <div className="text-center mt-4">
                <p className="text-center mt-4">Back to account login? <Link to="/auth/login">Sign In</Link></p>
        </div>
        </div>
        </div>
    </div>
    );
}

export default ForgotPassword;