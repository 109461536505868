import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Helpers from "../../Config/Helpers";
import axios from "axios";
import { useParams } from "react-router-dom";

const Register = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const defaultUser = {
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    team_id: id ? Helpers.decryptNumber(id) : 0,
  };

  const [user, setUser] = useState(defaultUser);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [ipAddress, setIpAddress] = useState("");

  // Fetch the IP address and log it
  useEffect(() => {
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        const ip = response.data.ip;
        setIpAddress(ip);
        console.log(`User IP Address: ${ip}`); // Log the IP address
      })
      .catch(error => {
        console.error("Error fetching IP address", error);
      });
  }, []);

  const handleRegister = () => {
    setIsLoading(true);
    setErrors({});
    const registrationData = { ...user, ip_address: ipAddress };
    axios
      .post(`${Helpers.apiUrl}auth/register`, registrationData)
      .then((response) => {
        Helpers.toast("success", response.data.message);
        localStorage.setItem("user_id", response.data.user_id);
        navigate("/auth/verify-email");
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          setErrors(error.response.data || {});
          Helpers.toast(
            "error",
            error.response.data.email ||
              error.response.data.password ||
              error.response.data.name
          );
        }
        setIsLoading(false);
      });
  };

  useEffect(() => {
    Helpers.toggleCSS();
  }, [location.pathname]);

  return (
    <div className="tyn-root" style={{ background: "#F8F8F8" }}>
      <div className="tyn-content tyn-auth tyn-auth-centered">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="my-3 text-center">
                <img src="/app/Legaldrafting.png" className="w200" />
              </div>
              <div className="card border-0 card-custom" style={{ background: "white" }}>
                <div className="p-4">
                  <h3 className="white">Create Free Account</h3>
                  <div className="row g-3">
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label white-sub">Your Name</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            value={user.name}
                            onChange={(e) => setUser({ ...user, name: e.target.value })}
                            className="form-control"
                            placeholder="Your Name"
                          />
                          <small className="text-danger">
                            {errors.name ? errors.name[0] : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label white-sub">Email Address</label>
                        <div className="form-control-wrap">
                          <input
                            type="text"
                            value={user.email}
                            onChange={(e) => setUser({ ...user, email: e.target.value })}
                            className="form-control"
                            placeholder="youremail@example.com"
                          />
                          <small className="text-danger">
                            {errors.email ? errors.email[0] : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label d-flex white-sub">Password</label>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            value={user.password}
                            onChange={(e) => setUser({ ...user, password: e.target.value })}
                            className="form-control"
                            placeholder="Password"
                          />
                          <small className="text-danger">
                            {errors.password ? errors.password[0] : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label className="form-label d-flex white-sub">Confirm Password</label>
                        <div className="form-control-wrap">
                          <input
                            type="password"
                            value={user.password_confirmation}
                            onChange={(e) => setUser({ ...user, password_confirmation: e.target.value })}
                            className="form-control"
                            placeholder="Confirm Password"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        onClick={handleRegister}
                        disabled={isLoading}
                        className="btn w-100"
                        style={{ background: "#0C1B44", color: 'white' }}
                      >
                        {isLoading ? "Creating Your Account" : "Create Free Account"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center mt-4">
                <p className="small white-sub">
                  Already have an account?{" "}
                  <Link className="color-primary" to="/auth/login">
                    Login to Account
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
