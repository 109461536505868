import React from 'react'
import { useState, useEffect, useRef } from 'react';
import styles from '../../CSS/Mockup-Trial.module.css'
import DragAndDropUploader from '../../Components/Mockup Trial Components/DraggablePDF';
import { BiLock } from 'react-icons/bi';
import pdfToText from 'react-pdftotext'
import Helpers from '../../Config/Helpers';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { MdPersonalInjury } from "react-icons/md";
import { BsPersonFillExclamation } from "react-icons/bs";
import MapMessages from '../../Components/Mockup Trial Components/Messages';
import CaseDetails from '../../Components/Mockup Trial Components/CaseDetails';
import ChatInput from '../../Components/Mockup Trial Components/ChatInput';

const defaultMessage = {
    plaintiff: "",
    defendant: ""
}

const defaultCase = {
    chat_id: "",
    caseNo: null,
    title: ""
};

export default function () {
    const [generating, setGenerating] = useState(false);
    const [uploadPlaintiff, setUploadPlaintiff] = useState(false);
    const [uploadDefendant, setUploadDefendant] = useState(false);
    const [trialIssues, setTrialIssue] = useState(defaultMessage);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [plaintiffMessage, setPlaintiffMessage] = useState('');
    const [plaintiffMessages, setPlaintiffMessages] = useState([]);
    const [defendantMessage, setDefendantMessage] = useState('')
    const [defendantMessages, setDefendantMessages] = useState([]);
    const [firstMessage, setFirstMessage] = useState(false);
    const [caseDetails, setCaseDetails] = useState(defaultCase);
    const [user, setUser] = useState(Helpers.getItem('user'));
    const [expired, setExpired] = useState(false);
    const [show, setShow] = useState(false);
    const messagesEndRef = useRef(null);
    const listRef = useRef(null);
    const navigate = useNavigate();
    let { trial_id } = useParams();

    const handleClose = () => {
        if (caseDetails.caseNo == null || caseDetails.title == "") {
            Helpers.toast('error', "Please Provide Case No and Case Title");
            return;
        }
        setShow(false)
    };
    const handleShow = () => setShow(true);

    const handleMessages = (plaintiff, defendant, formData, trialIssues, plaintiffMessage, defendantMessage, setPlaintiffMessages, setDefendantMessages) => {
        if (!plaintiff && !defendant) {
            formData.append('message', JSON.stringify(trialIssues));
            formData.append('plaintiff', 1);
            formData.append('defendant', 1);
        } else if (plaintiff && !defendant) {
            setPlaintiffMessages([...plaintiffMessages, plaintiffMessage]);
            formData.append('message', JSON.stringify("Plaintiff: " + plaintiffMessage));
            formData.append('plaintiff', 1);
            formData.append('defendant', 0);
        } else if (!plaintiff && defendant) {
            setDefendantMessages([...defendantMessages, defendantMessage]);
            formData.append('message', JSON.stringify("Defendant: " + defendantMessage));
            formData.append('plaintiff', 0);
            formData.append('defendant', 1);
        }
    };

    const getResponse = (plaintiff, defendant) => {
        setGenerating(true);
        if (generating) {
            return;
        }
        const formData = new FormData();

        handleMessages(
            plaintiff,
            defendant,
            formData,
            trialIssues,
            plaintiffMessage,
            defendantMessage,
            setPlaintiffMessages,
            setDefendantMessages
        );

        formData.append('chat_id', caseDetails.chat_id);
        formData.append('title', caseDetails.title);

        formData.append('user_id', Helpers.getItem("id"));
        axios.post(`${Helpers.apiUrl}judges`, formData, Helpers.authHeaders).then(response => {
            setGenerating(false);
            console.log(response);
            setMessage(response.data.choices[0].message.content);
            setMessages([...messages, response.data.choices[0].message.content]);
        }).catch(error => {
            setGenerating(false);
            Helpers.toast('error', error.response.data.message)
            console.log(error)
            setMessages([...messages, 'An Error Occured, Please Try Again']);
            if (error.response.data.code === 503) {
                navigate('/user/pricing')
            }
        })
    }

    const countWords = (text) => {
        const words = text.trim().split(/\s+/);
        const filteredWords = words.filter(word => word !== '');
        return filteredWords.length;
    };

    function extractText(file) {
        return new Promise((resolve, reject) => {
            pdfToText(file)
                .then(text => {
                    resolve(text);
                })
                .catch(error => {
                    Helpers.toast('error', error)
                    console.error("Error extracting text:", error);
                    reject(error);
                });
        });
    }
    const handlePlaintiffFileSelected = async (selectedFile) => {
        if (expired) {
            return;
        }
        try {
            // Handle file upload logic here (e.g., using axios or fetch)
            const plaintiffText = await extractText(selectedFile);
            setTrialIssue({ ...trialIssues, plaintiff: plaintiffText });
            const no_words = countWords(plaintiffText);
            if (no_words < 2000) {
                setUploadPlaintiff(true); // Simulate upload completion for demo
            } else if (no_words <= 0) {
                Helpers.toast('error', "Please Make sure the PDF has words Less then 2000 and It doesn't contain Photo's")
            }
            else {
                Helpers.toast('error', "Please Make sure the PDF has words Less then 2000")
            }
        } catch (err) {
            console.log(err)
        }
    };
    const handleDefendentFileSelected = async (selectedFile) => {
        if (expired) {
            return;
        }
        try {
            // Handle file upload logic here (e.g., using axios or fetch)
            const defendantText = await extractText(selectedFile);
            setTrialIssue({ ...trialIssues, defendant: defendantText });
            const no_words = countWords(defendantText);
            if (no_words < 2000) {
                setUploadDefendant(true);
            } else if (no_words <= 0) {
                Helpers.toast('error', "Please Make sure the PDF has words Less then 2000 and It doesn't contain Photo's")
            }
            else {
                Helpers.toast('error', "Please Make sure the PDF has words Less then 2000")
            }
        } catch (err) {
            console.log(err)
        }
    };

    useEffect(() => {
        listRef.current?.lastElementChild?.scrollIntoView()
    }, [messages, plaintiffMessages, defendantMessages]);

    useEffect(() => {
        setUser(JSON.parse(user))
        const userExpirationDate = JSON.parse(user).expiration_date;

        // Get the current date
        const currentDate = new Date();

        // Convert user's expiration date to a JavaScript Date object
        const expirationDate = new Date(userExpirationDate);
        const isExpired = currentDate > expirationDate;

        const exhaustedWords = user.no_words > user.used_words;
        // Check if the current date is after the expiration date
        if (isExpired || exhaustedWords) {
            setExpired(true);
        } else {
            // User has not expired
            setExpired(false)
        }
    }, []);

    useEffect(() => {
        setCaseDetails({ ...caseDetails, chat_id: trial_id })
        handleShow();
    }, [])
    return (
        <div
            className={`${styles.height_100} ${styles.overflow_hidden} ${styles.poppins} col-12 d-flex py-5 px-3`}
            style={{ backgroundColor: "#f4f5fb" }}
        >
            {/* Body */}
            <div className='d-flex flex-row justify-content-center align-items-center w-100'>
                {/* Plaintiff */}
                <div
                    className={`plaintiff col-4 ${styles.height_100} 
            ${styles.border_r} ${styles.plaintiff}
            ${styles.custom_card_for_mockup_trial} d-flex p-3
            ${!uploadPlaintiff && ("flex-row justify-content-center align-items-center")}`}>
                    <div className={`w-100`}>
                        {uploadPlaintiff ?
                            <>
                                <div>
                                    <h2
                                        className={`text-center text-bold ${styles.kanit}`}
                                        style={{ color: '#0b1948' }}> Plaintiff Chat</h2>
                                </div>

                                <div className='mt-1' style={{ height: '85%', overflowY: "scroll" }} ref={listRef}>
                                    <MapMessages
                                        icon={<MdPersonalInjury fill='#bf8513' />}
                                        text={"Plaintiff"}
                                        messages={plaintiffMessages}
                                        chatbot={false}
                                        isLoading={generating}
                                    />
                                    <div ref={messagesEndRef} />
                                </div>

                                <div>
                                    <ChatInput getReply={() => getResponse(true, false)} message={plaintiffMessage}
                                        setMessage={setPlaintiffMessage} isLoading={generating} />
                                </div>
                            </>
                            :
                            <DragAndDropUploader person={"Plaintiff"} onFileSelected={handlePlaintiffFileSelected} />
                        }
                    </div>
                </div>



                {/* Chatbot */}
                <div
                    className={`chatbot ${styles.z_index_top} 
            ${styles.custom_card_for_mockup_trial}
             ${styles.height_100} ${styles.chatbot} col-4
             ${messages.length >= 0 && ('d-flex flex-row justify-content-center align-items-center')}
             ${!(uploadPlaintiff && uploadDefendant && messages.length >= 0) &&
                        ("d-flex flex-row justify-content-center align-items-center")}`}
                >
                    <div className='d-flex flex-column justify-content-center align-items-center'
                        style={{ height: "100%", width: "100%" }}>
                        {(uploadDefendant && uploadPlaintiff && !generating && !(message.length > 0)) && (
                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                <button
                                    className='btn btn-prim'
                                    onClick={() => {
                                        getResponse(false, false)
                                        setFirstMessage(true)
                                    }}
                                > Start Trial</button>
                            </div>
                        )}
                        {(firstMessage) && (
                            <>
                                <div>
                                    <h2
                                        className={`text-center text-bold ${styles.kanit}`}
                                        style={{ color: '#0b1948' }}> Chatbot</h2>
                                </div>

                                <div className='mt-1' style={{ height: '90%', overflowY: "scroll", width: "100%" }} ref={listRef}>
                                    <MapMessages
                                        icon={<i className="fa-solid fa-gavel" fill='#bf8513'></i>}
                                        text={"AI Judge"}
                                        messages={messages}
                                        chatbot={true}
                                        isLoading={generating}
                                    />
                                    <div ref={messagesEndRef} />
                                </div>
                            </>
                        )}
                    </div>
                    {!(uploadDefendant && uploadPlaintiff) &&
                        (<Locked text={"Kindly Upload the PDF's of Plaintiff and Defendants"}>
                        </Locked>)}
                </div>


                {/* Defendant */}
                <div
                    className={`plaintiff col-4 ${styles.height_100}
            ${styles.border_l} ${styles.defendant} 
            ${styles.custom_card_for_mockup_trial} d-flex
            ${!uploadDefendant && ("flex-row justify-content-center align-items-center")}`}>
                    <div
                        className='w-100'
                    >
                        {!uploadDefendant && (<DragAndDropUploader person={"Defendant"} onFileSelected={handleDefendentFileSelected} />)}
                        {uploadDefendant &&
                            <>
                                <div>
                                    <h2
                                        className={`text-center text-bold ${styles.kanit}`}
                                        style={{ color: '#0b1948' }}> Defendant Chat</h2>
                                </div>

                                <div className='mt-1' style={{ height: '85%', overflowY: "scroll" }} ref={listRef}>
                                    <MapMessages
                                        icon={<BsPersonFillExclamation fill='#bf8513' />}
                                        text={"Defendant"}
                                        messages={defendantMessages}
                                        chatbot={false}
                                        isLoading={generating}
                                    />
                                    <div ref={messagesEndRef} />
                                </div>

                                <div>
                                    <ChatInput getReply={() => getResponse(false, true)} message={defendantMessage}
                                        isVoice={false} setMessage={setDefendantMessage} isLoading={generating} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <CaseDetails
                caseDetails={caseDetails}
                setCaseDetails={setCaseDetails}
                handleShow={handleShow}
                handleClose={handleClose}
                show={show}></CaseDetails>
        </div>
    )
}



const Locked = ({ text }) => {
    return (
        <div
            className={`${styles.min_height} col-12 d-flex flex-column justify-content-center align-items-center`}
            style={{ color: "#0C0C0C", position: "relative" }}>
            <BiLock fill='#8B8589' size={'4rem'} /><br></br>
            <h5 className='text-center'>{text}</h5>
        </div>
    )
}
