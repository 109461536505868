import React, { useState, useEffect } from 'react'
import Helpers from '../Config/Helpers'

export default function ExpirationAlert() {
    const [user, setUser] = useState(Helpers.getItem('user'));
    const [expired, setExpired] = useState(false);

    useEffect(()=>{
        setUser(JSON.parse(user))
        const userExpirationDate = JSON.parse(user).expiration_date;
        const noOfWords = JSON.parse(user).no_words;
        const noUsedWords = JSON.parse(user).used_words;

        // Get the current date
        const currentDate = new Date();

        // Convert user's expiration date to a JavaScript Date object
        const expirationDate = new Date(userExpirationDate);
        const isExpired = currentDate > expirationDate;

        const exhaustedWords = noUsedWords >= noOfWords;
        console.log("Number of Words", noOfWords,"Used Words", noUsedWords, "Exhausted Limit",exhaustedWords, isExpired);
        // Check if the current date is after the expiration date
        if (isExpired || exhaustedWords) {
          setExpired(true);
        } else {
            // User has not expired
            setExpired(false)
        }
    }, [])
  return (
    <>
        {expired && (<div className="alert alert-warning" role="alert" style={{borderRadius: '30px'}}>
        Your plan has <strong>exired</strong>. Upgrade your plan using our <strong>Membership</strong> Plans and enjoy!
        </div>)}
    </>
  )
}
