import React, { useState, useEffect } from "react";
import axios from "axios";
import Helpers from "../../../Config/Helpers";
import PageLoader from "../../../Components/Loader/PageLoader";
import { Link, json } from "react-router-dom";
import { TbAbc } from "react-icons/tb";
import { IoMdCheckmark, IoIosAddCircle } from "react-icons/io";
import { MdEdit, MdDelete } from "react-icons/md";
import { useLocation } from 'react-router-dom';

const defaultPlan = {
    name: "",
    monthly_price: "",
    plan_type: "",
    no_words: "",
    mockup_trial: 0,
    no_of_days: 7,
    description: "",
    no_member: 0,
    is_trial: 1
}

const defaultFeature = {
    feature:"",
    pricing_plan_id:"",
}

const TrialPlan = ({pln}) => {
    const [plan, setPlan] = useState(defaultPlan);
    const [plans, setPlans] = useState([]);
    const [errors, setErrors] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedPlan,setSelectedPlan] = useState(0);
    const [showFeatures, setShowFeatures] = useState(false);
    const [parentPlan, setParentPlan] = useState(defaultPlan);
    const [feature, setFeature] = useState(defaultFeature);
    const token = localStorage.getItem('');
    const location = useLocation();

    const getPricing = () => {
        axios.get(`${Helpers.apiUrl}pricing/all`,Helpers.authHeaders).then(response => {
            setPlans(response.data.plans);
            Helpers.toast(response.data.message);
        }).catch(err => {
            Helpers.toast('error', err.message);
        });
    };

    const savePlan = () => {
        setIsLoading(true)
        setErrors({})
        axios.post(`${Helpers.apiUrl}pricing/save-plan`, plan,Helpers.authHeaders).then(response=>{
            setPlans(response.data.plans);
            setIsLoading(false);
            setShowFeatures(false)
            setShowForm(false)
            setPlan(defaultPlan);
            setShowForm(false);
        }).catch(error=>{
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        })
    }

    const handleDeletePlan = (id) => {
        setIsLoading(true)
        setErrors({})
        axios.get(`${Helpers.apiUrl}pricing/delete-plan/${id}`, Helpers.authHeaders).then(response=>{
            setPlans(response.data.plans);
            setIsLoading(false);
            setPlan(defaultPlan);
            setShowForm(false);
        }).catch(error=>{
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors);
            setIsLoading(false);
        })        
    }

    const saveFeature = () => {
        if(feature.feature){
            setIsLoading(true);
            let data = feature;
            data.pricing_plan_id = selectedPlan;
            axios.post(`${Helpers.apiUrl}plans/feature/save`, data, Helpers.authHeaders).then(response => {
                setPlans(response.data.plans);
                setPlan(response.data.plan);
                setFeature(defaultFeature);
                setShowFeatures(false);
                setShowForm(false)
                Helpers.toast("success", response.data.message);
                setIsLoading(false);
            }).catch(error => {
                Helpers.toast("error", error.response.data.message);
                setIsLoading(false);
            });
        }else{
            Helpers.toast("error", "Please add feature to save");
        }
    }

    const deleteFeature = (featureId) => {
        setIsLoading(true)
        axios.get(`${Helpers.apiUrl}plans/feature/delete/${featureId}`, Helpers.authHeaders).then(response => {
            setPlan(response.data.plan);
            setPlans(response.data.plans);
            setShowFeatures(false);
            setIsLoading(false)
            Helpers.toast("success", response.data.message);
        }).catch(error=>{
            setErrors(error.response.data.errors);
            Helpers.toast('error', error.response.data.message);
            setShowFeatures(false)
            setShowForm(false)
            setIsLoading(false)
        })
    }

    const editFeature = fearureToEdit => {
        setFeature(fearureToEdit);
    }

    const addFeature = planId => {
        setSelectedPlan(planId);
        let p = plans.find(p => p.id === planId);
        console.log(plan)
        setPlan(p);
        setShowFeatures(true);
    }

    const handleFeatureCancel = () => {
        setPlan(defaultPlan);
        setSelectedPlan(0);
        setFeature(defaultFeature);
        setShowFeatures(false);
    }

    const handleFormToggle = () => {
        setShowForm(!showForm)
    }

    const handleCancelForm = () => {
        setPlan(defaultPlan);
        handleFormToggle();
    }

    const handleEdit = (pln) => {
        setPlan(pln);
        handleFormToggle();
    }

    const handleChange = (event) => {
        setPlan({...plan, is_trial: (event.target.checked ? 1 : 0)});
      };

    useEffect(() => {
        getPricing();
    }, []);

    useEffect(() => {
        if (location.state && location.state.pln) {
            setParentPlan(location.state.pln);
            let ParentTrial ={
                name: location.state.pln.name + " Trial",
                plan_type: location.state.pln.plan_type + " Trial",
                monthly_price: 1,
                no_of_days: 7,
                no_words: location.state.pln.no_words,
                is_trial: 1,
                parent_id: location.state.pln.id,
                mockup_trial: 0,
                no_member: 0
            }
            setPlan(ParentTrial);
            setShowForm(true);
        }
    }, [location.state]);
    return(
        <>
        <div className="nk-content bg-white col-md-12" 
        style={{
            height: `${(showForm || showFeatures) ? '100vh': 'max-content'}`,
            overflowY: "auto"
            }}>
            <div className="container-xl">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content d-flex justify-content-between">
                                    <div className=" m-2">
                                    <h2 className="display-6">Trial Plans</h2>
                                    <p>Manage all the Trial Plans</p>
                                    </div>

                                    {(!showForm && !showFeatures) && (<button className="btn btn-prim m-5" onClick={handleFormToggle}><IoIosAddCircle/> Add Trial Plan</button>)}
                                </div>
                            </div>
                        </div>

                        { !(!isLoading || (plans.length > 0)) ? <PageLoader/> :(<div className="nk-block">
                        {(!showForm && !showFeatures) && (
                            <div className="row m-2 col-md-12">
                                {/* Card */}
                            {plans.map(pln=>{
                                return(
                            <div className={`col-md-4 ${pln.is_trial == 1 ? '' : 'd-none'}`}>
                            <div className="card p-1 m-1 ml-0 shadow"                                 
                            style={{
                                    height: "70vh", 
                                    overflowY: "scroll", 
                                    WebkitOverflowScrolling: 'touch', 
                                    msOverflowStyle: 'none', 
                                    scrollbarWidth: 'none'
                                }}>
                            <div className="card-body" >
                                <p>{pln.plan_type}</p>
                                <h3 className="sub-heading fw-semibold">{pln.name}</h3>
                                {pln.parent_plan && (<span className="caption-text fs-5 fw-normal">
                                    Renews to Monthly Price of {pln.parent_plan?.monthly_price}
                                </span>)}
                                <h1 className="heading fw-bolder fs-1">
                                    ${pln.monthly_price}
                                    <span className="caption-text fs-5 fw-normal">
                                         / one time
                                    </span>
                                </h1>

                                <div 
                                className="col-md-12 row mx-auto"
                                >
                                
                                <button 
                                className="btn btn-outline-prim w-50 my-2" 
                                onClick={() => handleEdit(pln)}>
                                Edit Plan
                                </button>

                                <button 
                                className="btn btn-outline-prim w-50 my-2" 
                                onClick={()=> addFeature(pln.id)}>
                                    Edit Feature
                                </button>

                                </div>
                                <div className="d-flex flex-column my-2">
                                
                                <p className="m-1">
                                    <IoMdCheckmark 
                                    className="text-prim my-1 fw-semibold"/> 
                                    Words: {pln.no_words}
                                    </p>

                                {pln.plan_features.map((e,index)=>{return(
                                <>
                                <p className="m-1">
                                    <IoMdCheckmark className="text-prim my-1 fw-semibold"/>
                                    {e.feature}
                                </p>
                                </>
                                )})}
                                </div>
                                <button className="btn btn-second w-100" onClick={()=>handleDeletePlan(pln.id)}>Delete</button>
                            </div>
                            </div>
                            </div>
                                )
                            })
                            }
                            </div>)}

                            {showForm && 
                            <>
                            <div className="card shadown-none">
                                <div className="card-body">
                                    <div className="row g-3 gx-gs">
                                        <form 
                                        >
                                        {/* onSubmit={saveQuery} */}
                                        
                                        <div className="row">
                                        <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Plan Type</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" 
                                                        value={plan.plan_type} 
                                                        onChange={e=> setPlan({...plan, plan_type: e.target.value})}
                                                        className="form-control" 
                                                        placeholder="Enter Trial Plan Type" />
                                                        <small className="text-danger">
                                                            { errors.plan_type ? errors.plan_type[0] : '' }
                                                        </small>
                                                    </div>
                                                </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Name</label>
                                                <div className="form-control-wrap">
                                                    <input type="text" 
                                                    value={plan.name} 
                                                    onChange={e=> setPlan({...plan, name: e.target.value})}
                                                    className="form-control" 
                                                    placeholder="Enter Trial Plan Name" />
                                                    <small className="text-danger">
                                                        { errors.name ? errors.name[0] : '' }
                                                    </small>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>

                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Price</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" 
                                                        value={plan.monthly_price} 
                                                        onChange={e=> setPlan({...plan, monthly_price: e.target.value})}
                                                        className="form-control" 
                                                        placeholder="Enter Price for Trial" />
                                                        <small className="text-danger">
                                                            { errors.monthly_price ? errors.monthly_price[0] : '' }
                                                        </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            {/*<div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Team Members</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" 
                                                        value={plan.no_member} 
                                                        onChange={e=> setPlan({...plan, no_member: e.target.value})}
                                                        className="form-control" placeholder="Enter Number of members in a team" />
                                                        <small className="text-danger">
                                                            { errors.no_member ? errors.no_member[0] : '' }
                                                        </small>
                                                        </div>
                                                    </div>
                                                </div>*/}
                                            </div>


                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">No of Words:</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" 
                                                        value={plan.no_words} 
                                                        onChange={e=> setPlan({...plan, no_words: e.target.value})}
                                                        className="form-control" 
                                                        placeholder="Enter No of Words for Trial" />
                                                        <small className="text-danger">
                                                            { errors.no_words ? errors.no_words[0] : '' }
                                                        </small>
                                                        </div>
                                                    </div>
                                                </div>


                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">No of Days:</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" 
                                                        value={plan.no_of_days} 
                                                        onChange={e=> setPlan({...plan, no_of_days: e.target.value})}
                                                        className="form-control" 
                                                        placeholder="Enter No of Words for Trial" />
                                                        <small className="text-danger">
                                                            { errors.no_of_days ? errors.no_of_days[0] : '' }
                                                        </small>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Description:</label>
                                                    <div className="form-control-wrap">
                                                    <textarea 
                                                    value={plan.description}
                                                    onChange={e=>setPlan({...plan, description: e.target.value})}
                                                    className="form-control" 
                                                    id="exampleFormControlTextarea1"
                                                    rows="3"></textarea>
                                                        <small className="text-danger">
                                                            { errors.description ? errors.description[0] : '' }
                                                        </small>
                                                        </div>
                                                    </div>
                                                </div>
                                        </form>
                                        <div className="col-md-12 d-flex justify-content-end">
                                            <button className="btn btn-prim m-1" 
                                            onClick={savePlan}
                                            disabled={isLoading}>
                                            {isLoading ? 'Saving...' : 'Save Trial Plan'}
                                            </button>
                                            <button className="btn btn-outline-second ml10 m-1"
                                            onClick={handleCancelForm}  
                                            disabled={isLoading}
                                            >Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </>}


                            {(!showForm && showFeatures) && (
                            <>
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title">
                                        Add New Feature
                                    </h3>
                                </div>
                            </div>
                            <div className="card shadown-none">
                                <div className="card-body">
                                    <div className="row g-3 gx-gs">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">No of Words:</label>
                                            <div className="form-control-wrap">
                                                <input type="text" 
                                                value={feature.feature} onChange={e=> setFeature({...feature, feature: e.target.value})}
                                                className="form-control" placeholder="Enter No of Words for Plan" />
                                                <small className="text-danger">
                                                    { errors.feature ? errors.feature[0] : '' }
                                                </small>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn btn-prim" disabled={isLoading} onClick={saveFeature}>{isLoading ? 'Saving...' : 'Save Feature'}</button>
                                            <button className="btn btn-outline-second ml10" onClick={handleFeatureCancel}>Cancel</button>
                                        </div>
                                    </div>
                                    <div className="row g-3 gx-gs mt-3">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Sr. #</th>
                                                    <th>Feature</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {plan.plan_features.map((plan_feature, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{ index + 1 }</td>
                                                            <td>
                                                                {plan_feature.feature}
                                                            </td>
                                                            <td>
                                                                <button onClick={() => editFeature(plan_feature)} className="btn btn-outline-prim btn-sm ml5">
                                                                    <MdEdit/> Edit
                                                                </button>
                                                                <button onClick={() => deleteFeature(plan_feature.id)} className="btn btn-outline-second btn-sm ml5">
                                                                    <MdDelete /> Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </>)}

                        </div>)}
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default TrialPlan;