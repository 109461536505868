import { IoMdCheckmark } from "react-icons/io";
import { useState, useEffect } from 'react';
import axios from "axios";
import Helpers from "../Config/Helpers";
import { useNavigate } from "react-router-dom";
import PageLoader from "../Components/Loader/PageLoader";

const Plans = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]);
    const token = localStorage.getItem('token');
    const auth = localStorage.getItem('user');

    const getPricing = () => {
        axios.get(`${Helpers.apiUrl}pricing/all`, Helpers.authHeaders).then(response=>{
            setPlans(response.data.plans);
            Helpers.toast(response.data.message);
        }).catch(err=>{
            Helpers.toast('error', err.message);
        })
    }

    const handleTrialBtn = (id) => {
        let user = Helpers.getItem("user", true);
        let token = Helpers.getItem("token");
        if(user && token){
            navigate(`/user/pricing`)
        }else{
            navigate(`/auth/Subscription/${id}`);
        }
    }

    useEffect(()=>{
        getPricing();
    },[])
    return(
        <>
            <div className='pt-5'>
                <div className='pt-5'>
                    <section className="service-style-three pb_150">
                        <div className="auto-container">
                        <div data-animation-box className="sec-title mb_55 centred">
                            <span
                                data-animation-text
                                className="sub-title-three overlay-anim-white-bg"
                                data-animation="overlay-animation"
                            >
                                Pricing
                            </span>
                            <h2 className='fw-bold'> AI Pricing Plan</h2>
                            <p className="text-muted">Get the best services for Best AI Legal Software</p>
                        </div>
                        
                        {/* Main Div of Pricing Plan */}
                        {(!(plans.length > 0)) ? <PageLoader/> :
                        (
                        <div className="container">
                        <div className="row">
                        {plans
                        .sort((a, b) => b.is_trial - a.is_trial)
                        .map(pln=>{
                            return(
                            <div className="col-md-4">
                            <div className={`text-center p-2 py-5 m-3 rounded-5 shadow-bottom-right {pln.is_trial === 1 ? 'd-none': ''}`}> 
                            {/* style={{border: "1px solid rgb(196, 130, 25)"}} */}
                            <small className="text-prim m-1">{pln.plan_type}</small>
                            <h2 className="fw-bold m-1">${pln.monthly_price}</h2>
                            {pln.parent_plan ?
                            <span className="caption-text fw-normal">
                                <small>
                                    Renews {pln.parent_plan?.monthly_price}/mon
                                </small>
                            </span>: <></>}
                            <small className={`text-muted d-block m-1`}>{(pln.is_trial === 0) ? 'Per Month' : 'One Time'}</small>
                            <button 
                            className={`btn-outline-prim btn md:w-75 my-2`} 
                            onClick={(e) => handleTrialBtn(pln.id)}
                            >{pln.is_trial === 0 ? 'Subscribe' : 'Try Trial'}
                            </button>
                            <div className="col-md-12">
                            {pln.plan_features.map((e,index)=>{return(
                            <>
                            <p className="m-1"><IoMdCheckmark className="text-prim my-1 fw-semibold"/>{e.feature}</p>
                            </>
                            )})}
                            </div>
                        </div>
                        </div>
                    )})}
                        </div>
                    </div>
                    )}

                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default Plans;