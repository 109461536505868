import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../../Config/Helpers";

const RecoverPassword = () => {
    const navigate = useNavigate();

    const defaultUser = {
        user_id: "",
        password: "",
        password_confirmation: "",
    }

    const [user, setUser] = useState(defaultUser);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleRecoverPassword = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}auth/recover-password`, user, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            localStorage.clear();
            navigate("/auth/login");
            setIsLoading(false);
        }).catch(error => {
            setErrors(error.response.data.errors || {});
            Helpers.toast("error", error.response.data.message);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        const checkUserId = localStorage.getItem("user_id");
        if(checkUserId){
            setUser({...user, user_id: checkUserId});
        }else{
            navigate("/auth/register");
        }
    }, []);

    return (
        <div className="tyn-root " style={{ background: '#F8F8F8' }}>
    
            <div className="tyn-content tyn-auth tyn-auth-centered d-flex justify-content-center align-items-center"  style={{minHeight: '100vh', overflowY: "hidden"}}>
            <div className="container">
                <div className="row justify-content-center" >
                <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9"  style={{ background: 'White', padding: '30px', borderRadius: '10px' }}>
                    <div className="my-3 text-center">
                    <img src="	https://legalmasterai.com/app/Legaldrafting.png" className="w200" />
                    </div>
                    <div className="row justify-content-center text-center">
                        <div className="col-lg-12 col-xl-10 col-xxl-9">
                            <h6 className="overline-title color-primary">Recover Password</h6>
                            <p className="title">Create a new password for your account</p>
                        </div>
                    </div>
                    <form onSubmit={handleRecoverPassword}>
                        <div className="form-group" >
                            <label
                                htmlFor="password"
                                className="form-label white-sub"
                            >
                                Password
                            </label>
                            <div className="form-control-wrap">
                                <input type="password" value={user.password} onChange={e => setUser({...user, password: e.target.value})} className="form-control form-control-lg" placeholder="Enter Password" />
                                <small className="text-danger">{ errors.password ? errors.password[0] : '' }</small>
                            </div>
                        </div>
                        <div className="form-group" >
                            <label className="form-label" htmlFor="confirm-password">Confirm Password</label>
                            <div className="form-control-wrap">
                                <input type="password" value={user.password_confirmation} onChange={e => setUser({...user, password_confirmation: e.target.value})} className="form-control form-control-lg" placeholder="Confirm Password"  />
                            </div>
                        </div>
                        <div className="form-group pt-2"><button type="submit" className="btn w-100" style={{ background: "#0C1B44" , color : 'white' }} disabled={isLoading} onClick={handleRecoverPassword}>{isLoading ? 'Please wait...' : 'Update Password'}</button></div>
                    </form>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default RecoverPassword;