import React, { useState, useEffect } from 'react'
import DOMPurify from 'dompurify';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams from React Router
import axios from 'axios';
import Helpers from '../Config/Helpers';
import PageLoader from '../Components/Loader/PageLoader';

export default function Blog() {
  const { id } = useParams(); // Get the ID from the URL params
  const [data, setData] = useState(null);
  const [cleanContent, setCleanContent]  = useState(<div></div>);
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState('');
  const [categorizedBlogs, setCategorizedBlogs] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}blog/show/${id}`);
      setData(response.data.blog);
      const cleaned = DOMPurify.sanitize(response.data.blog.content);
      setCleanContent(cleaned);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  
  const getBlogs = () => {
    setLoading(true);
    axios.get(`${Helpers.apiUrl}blog/getAll`).then((response) => {
          setBlogs(response.data.blogs);
          setPage(response.data.blogs.current_page);
          setLoading(false);
      }).catch((error)=> {
          console.log(error.response.data.message);
          Helpers.toast('error', error.response.data.message);
          setLoading(false);
      })
    }

    const getCategory = () => {
      axios.get(`${Helpers.apiUrl}category/get`, Helpers.authHeaders)
      .then((res)=>{
        setCategories(res.data.categories)
      })
      .catch((err)=>{
        console.log(err)
      })
    }

    const getCategoryBlog = (category) => {
      setLoading(true)
      setCategory(category);
      setCategorizedBlogs(true);
      const data = {
        category: category
      }
      axios.post(`${Helpers.apiUrl}blog/category`, data,Helpers.authHeaders)
      .then((res)=>{
        setLoading(false);
        setBlogs(res.data.blogs)
      })
      .catch((err)=>{
        console.log(err);
        Helpers.toast('error', err.response.data.message)
        setLoading(false);
      })
    }
    
    const handleBlogClick = (blogId) => {
      navigate(`/Screen/blog/${blogId}`);
    };
    
    const handleBackwards = () => {
      navigate(`/Screen/Blogs`)
    }

    useEffect(()=>{
      fetchData();
      getBlogs();
      getCategory();
    }, [id])
    return (
      <>
          {data ? (<>
              <meta name="keywords" content={`${data.metadata ? `${data.metadata.meta_title} ${data.metadata.meta_tags}` : ``}`} />
              <meta name="description" content={`${data.metadata ? `${data.metadata.meta_description}` : ``}`} />
              </>) :
          (<></>)}
            <section className="page-title centred pt_100 pb_100">
                <div className="pattern-layer">
                    <div
                        className="pattern-1"
                        style={{backgroundImage: "url(/assets/images/shape/shape-64.png)"}}
                    />
                    <div
                        className="pattern-2"
                        style={{ backgroundImage: "url(/assets/images/shape/shape-65.png)" }}
                    />
                </div>
            </section>

{(!loading) ? <section className="sidebar-page-container pb_150">
  <div className="default-pattern">
      <div className="pattern-1" style={{backgroundImage: 'url(/assets/images/shape/shape-68.png)'}}></div>
      <div className="pattern-2" style={{backgroundImage: 'url(/assets/images/shape/shape-69.png)'}}></div>
  </div>
  <div className="auto-container">
    <div className="row clearfix">
    <div className="col-lg-8 col-md-12 col-sm-12 content-side">
  <div className="blog-details-content">
    <div className="news-block-one">
      <div className="inner-box">
        <figure className="image-box">
          <img src={`${Helpers.imageUrl}/images/${data.image}`} alt={`${data.image}`} />
        </figure>
        <div className="lower-content">
          <div className="upper-box">
            <span className="category mr_30">{data.category}</span>
            <ul className="post-info">
              <li>
                <i className="icon-7" />
                March 16, 2023
              </li>
              <li>
                <i className="icon-8" />
                <a href="blog-details.html">Alex Beniwal</a>
              </li>
            </ul>
          </div>
          <h2>{data.title}</h2>
          <div className="text-box mb_30" dangerouslySetInnerHTML={{ __html: cleanContent }}></div>
          {/* <div className="post-share-option pt_60 pb_20">
            <ul className="post-category">
              <li>
                <span>Tags:</span>
              </li>
              <li>
                <a href="blog-detail.html">Activities</a>
              </li>
              <li>
                <a href="blog-detail.html">Depertment</a>
              </li>
              <li>
                <a href="blog-detail.html">City</a>
              </li>
              <li>
                <a href="blog-detail.html">Certificate</a>
              </li>
            </ul>
            <ul className="post-share">
              <li>
                <span>Share This :</span>
              </li>
              <li>
                <a href="blog-detail.html">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li>
                <a href="blog-detail.html">
                  <i className="fab fa-pinterest" />
                </a>
              </li>
              <li>
                <a href="blog-detail.html">
                  <i className="fab fa-twitter" />
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  </div>
</div>
          {/* <div className="pagination-wrapper pt_20">
            <ul className="pagination clearfix">
              <li>
                <a href="blog-2.html">
                  <i className="icon-42" />
                </a>
              </li>
              <li>
                <a href="blog-2.html" className="current">
                  1
                </a>
              </li>
              <li>
                <a href="blog-2.html">2</a>
              </li>
              <li>
                <a href="blog-2.html">3</a>
              </li>
              <li>
                <a href="blog-2.html">
                  <i className="icon-43" />
                </a>
              </li>
            </ul>
          </div> */}
      <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side shadow">
        <div className="blog-sidebar default-sidebar ml_20 py-3" style={{backgroundColor: "#fff"}}>
          <div className="sidebar-widget category-widget mb_70">
            <div className="widget-title mb_20">
              <h3>Categories</h3>
            </div>
            <div className="widget-content">
              <ul className="category-list clearfix">
                {categories.slice(0,5).map((c)=>(
                <li>
                  <a
                  style={{cursor: "pointer"}}
                  onClick={handleBackwards}>
                    {c.category}</a>
                </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="sidebar-widget post-widget mb_40">
            <div className="widget-title mb_25">
              <h3>Latest News</h3>
            </div>
            <div className="post-inner">
              {blogs.map((blog)=>{
              const date = new Date(blog.created_at);

              const options = { year: 'numeric', month: 'long', day: 'numeric' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              return(<div className="post">
                <figure className="post-thumb" style={{cursor: "pointer"}}>
                  <a onClick={()=>handleBlogClick(blog.id)}>
                    <img src={`${Helpers.imageUrl}/images/${blog.image}`} alt="" />
                  </a>
                </figure>
                <h5>
                  <a onClick={()=>handleBlogClick(blog.id)} style={{cursor: "pointer"}}>
                    {blog.title.length > 31 ? `${blog.title.slice(0,31)}...` : `${blog.title.slice(0,31)}`}
                  </a>
                </h5>
                <span className="post-date">
                  <i className="icon-46" />
                  {formattedDate}
                </span>
              </div>)})}
            </div>
          </div>
          <div className="sidebar-widget gallery-widget mb_60">
            <div className="widget-title mb_20">
              <h3>Latest News</h3>
            </div>
            <div className="widget-content">
              <ul className="image-list clearfix d-flex flex-wrap">
                {blogs.slice(0,5).map((blog)=>{
                  return(<li className='col-4 mt-1'>
                  <a
                    src={`${Helpers.imageUrl}/images/${blog.image}`}
                    className="lightbox-image"
                    data-fancybox="gallery"
                  >
                    <img className='rounded' src={`${Helpers.imageUrl}/images/${blog.image}`} alt="" />
                  </a>
                </li>)})}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> : <PageLoader/>}
    </>
  )
}
