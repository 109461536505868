import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Helpers from '../../../Config/Helpers';
import { IoNavigate } from "react-icons/io5";
import { MdOutlineTextFields } from "react-icons/md";
import PageLoader from '../../../Components/Loader/PageLoader';

const defaultButton = {
  name: "",
  button_text: "",
  button_link: ""
}

export default function Buttons() {
  const [button, setButton] = useState(defaultButton);
  const [buttons, setButtons] = useState([]);
  const [errors, setErrors] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getButtons = () => {
    setIsLoading(true);
    axios.get(`${Helpers.apiUrl}button/all`, Helpers.authHeaders).then(response=>{
      setButtons(response.data.buttons);
      setIsLoading(false);
      Helpers.toast("success", response.data.message);
    }).catch(error=>{
      setErrors({})
      Helpers.toast("error", error.response.error.message);
      setIsLoading(false);
    })
  }

  const saveButton = () => {
    setIsLoading(true);
    axios.post(`${Helpers.apiUrl}button/save`, button, Helpers.authHeaders).then(response=> {
      setButtons(response.data.buttons);
      setIsLoading(false);
      Helpers.toast("success", response.data.message)
    }).catch(error=>{
      setErrors({})
      Helpers.toast("error", error.response.error.message);
      setIsLoading(false);
    })
  }

  const handleInputChange = (e) => {
    const inputValue = e.target.value
    const baseUrl = window.location.origin;
    let updatedValue = inputValue;
  
    // Check if inputValue includes the baseUrl
    if (inputValue.includes(baseUrl)) {
      // Remove the baseUrl from the inputValue
      updatedValue = inputValue.replace(baseUrl, '');
    }
    
    // Update the button state with the updatedValue
    setButton({...button, button_link: updatedValue});
  }
  

  const toggleForm = () => {
    setShowForm(!showForm)
  }

  const handleEdit = (btn) => {
    setButton(btn);
    toggleForm();
  };

  useEffect(()=>{
    getButtons();
  },[])
  return (
    <>
      <div className="nk-content bg-white col-md-12" 
      style={{
          height:  '100vh',
          overflowY: "auto"
          }}>
          <div className="container-xl">
              <div className="nk-content-inner">

                    {/* Head */}
                        <div className="nk-block-head-content d-flex justify-content-between">
                          <div className=" m-2">
                            <h2 className="display-6">Button</h2>
                            <p>Manage all the Buttons</p>
                          </div>
                        </div>

                        {/* Body */}
                      {(isLoading) ? <PageLoader/> : (<div className="nk-content-body">                      

                      
                        {!showForm && (<div className="row m-2 col-md-12">
                          {buttons.map((btn)=> {
                            return(
                              <div className='col-md-4' key={btn.id}>
                                <div className='card p-1 m-1 ml-0 shadow'>
                                  <div className='card-body'>
                                    <h2>{btn.name}</h2>
                                    <p><MdOutlineTextFields className='mx-2 text-warning'/> {btn.button_text}</p>
                                    <p><IoNavigate  className='mx-2 text-warning'/>{btn.button_link}</p>
                                    <button className='btn btn-outline-prim col-md-12' onClick={()=>handleEdit(btn)}>Edit</button>
                                  </div>
                                </div>
                              </div>
                            )})}
                        </div>)}

                      {/* Editable Form */}
                        {showForm && (
                          <>
                            <div className="card shadown-none">
                              <div className="card-body">
                                <div className="row g-3 gx-gs">
                                  <form>

                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">Button Text</label>
                                      <div className="form-control-wrap">
                                          <input type="text" 
                                          value={button.button_text} onChange={e=> setButton({...button, button_text: e.target.value})}
                                          className="form-control" placeholder="Enter the text for this button" />
                                          <small className="text-danger">
                                              { errors.button_text ? errors.button_text[0] : '' }
                                          </small>
                                          </div>
                                      </div>
                                    </div>

                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">Button Link</label>
                                      <div className="form-control-wrap">
                                      <input type="text" 
                                      value={button.button_link} 
                                      onChange={handleInputChange}
                                      className="form-control" 
                                      placeholder="Enter the Link for this button" />
                                          <small className="text-danger">
                                              { errors.button_link ? errors.button_link[0] : '' }
                                          </small>
                                          </div>
                                      </div>
                                    </div>
                                    <div className='d-flex flex-row justify-content-end my-3'>
                                      
                                      <button 
                                      className='btn btn-prim mx-1'
                                      onClick={saveButton}
                                      disabled={isLoading}>
                                        {isLoading ? 'Saving' : "Save"}
                                      </button>
                                      
                                      <button 
                                      className='btn btn-outline-second mx-1' 
                                      disabled={isLoading} 
                                      onClick={toggleForm}>
                                        Cancel
                                      </button>

                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        
                    </div>)}
                  </div>
                </div>
                <small className='text-muted text-center mx-5'>You can directly Copy and paste the URL from the Website</small>
            </div>
    </>
  )
}
