import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";

import Helpers from "../../Config/Helpers";
import axios from "axios";
import PageLoader from "../../Components/Loader/PageLoader";

const Subscribe = () => {
  const id = useParams();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [email, setEmail] = useState(`${JSON.parse(localStorage.getItem('user')).email}`);
  const [plan, setPlan] = useState();

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!stripe || !elements) {
  //     console.error("Stripe has not loaded");
  //     return;
  //   }

  //   const cardElement = elements.getElement(CardElement);

  //   const { paymentMethod, error: paymentMethodError } =
  //     await stripe.createPaymentMethod({
  //       type: "card",
  //       card: cardElement,
  //       billing_details: {
  //         email: email,
  //       },
  //     });

  //   if (paymentMethodError) {
  //     Helpers.toast('error', paymentMethodError.message)
  //     console.error(paymentMethodError);
  //     return;
  //   }

  //   setIsLoading(true);
  //   const response = await fetch(`${Helpers.apiUrl}auth/subscribe`, {
  //     method: "POST",
  //     headers: {
  //       ...Helpers.authHeaders.headers
  //     },
  //     body: JSON.stringify({
  //       payment_method_id: paymentMethod.id,
  //       plan: plan.id,
  //       amount: amount,
  //       email: email,
  //       user_id: Helpers.getItem("id"),
  //     }),
  //   })


  //   const result = await response.json();
  //   setIsLoading(false);

  //   if (result.error) {
  //     setIsLoading(false)
  //     Helpers.toast('error', result.error)
  //     console.error(result.error);
  //     return;
  //   }

  //   const { error: confirmError } = await stripe.confirmCardPayment(
  //     result.clientSecret,
  //     {
  //       payment_method: paymentMethod.id,
  //     }
  //   );

  //   if (confirmError) {
  //     console.error(confirmError);
  //     return;
  //   }

  //   navigate("/user/dashboard");
  //   Helpers.toast("success", "Plan successfully Activated");
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!stripe || !elements) {
      console.error("Stripe has not loaded");
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: email,
        },
      });

    if (paymentMethodError) {
      Helpers.toast('error', paymentMethodError.message)
      console.error(paymentMethodError);
      return;
    }
    if (paymentMethod) {
      let data = {
        plan: plan.id,
        amount: amount,
        email: email,
        user_id: Helpers.getItem("id"),
        paymentMethod: paymentMethod.id
      }
      axios.post(`${Helpers.apiUrl}auth/subscribe`, data).then(response => {
        Helpers.toast("success", "Plan successfully Activated");
        console.log(response.data);
        Helpers.setItem('user', response.data.user, true);
        setTimeout(() => {
          window.location.href = '/user/dashboard';
        }, 1000);
      }).catch(error => {
        if (error.response) {
          Helpers.toast("error", error.response.data.message);
        } else {
          Helpers.toast("error", "Unexpected error occured");
        }
        setIsLoading(false);
      });
    } else {
      Helpers.toast("error", "Unexpected error occured");
      setIsLoading(false);
    }
  }

  const getSingle = () => {
    axios.get(`${Helpers.apiUrl}pricing/getOne/${id.id}`).then(res => {
      if (res.data.plan.monthly_price == 0) {
        Helpers.toast('error', 'Kindly Subscribe another Package')
        navigate('/user/dashboard')
      }
      setPageLoading(true);
      setPlan(res.data.plan)
      setAmount(parseFloat(res.data.plan.monthly_price));
    }).catch(err => {
      Helpers.toast('error', err.message);
    })
  }

  useEffect(() => {
    getSingle();
  }, [])
  return (
    <>
      <div
        className=" tyn-root bg-white"
      >
        <div className="tyn-content tyn-auth tyn-auth-centered">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div className="my-3 text-center">
                  <img src="/app/Legaldrafting.png" className="w200" />
                </div>

                {!(pageLoading) ? <PageLoader /> :
                  (<div className="card border-0 card-custom" style={{ background: 'white' }}>
                    <div className="p-4">
                      <h3 className=" text-center text-black ">Subscribe Now</h3>
                      <form onSubmit={handleSubmit} className="row g-3">
                        <div className="col-12">
                          <div className="form-group">
                            <label
                              htmlFor="amount"
                              className="form-label text-black"
                            >
                              Subscribtion ($):
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="number"
                                id="amount"
                                className="form-control "
                                value={amount}
                                disabled={true}
                                placeholder="Enter amount"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label
                              htmlFor="email"
                              className="form-label text-black"
                            >
                              Email:
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="email"
                                id="email"
                                className="form-control "
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter your email"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label
                              htmlFor="card-details"
                              className="form-label text-black"
                            >
                              Card Details:
                            </label>
                            <div className="form-control-wrap">
                              <CardElement
                                id="card-details"
                                className="form-control "
                                options={{
                                  style: {
                                    base: {
                                      fontSize: "16px",
                                      color: "black",
                                      "::placeholder": {
                                        color: "black",
                                      },
                                    },
                                    invalid: {
                                      color: "#fa755a",
                                    },
                                  },
                                }}
                              />
                            </div>

                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            disabled={isLoading}
                            type="submit"
                            className="btn w-100  "
                            style={{ background: "#0C1B44", color: 'white' }}>
                            {isLoading ? 'Loading...' : "Subscribe"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
