import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../../../Config/Helpers";
import Select from 'react-select';
import { FaPlusCircle ,FaRegEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import PageLoader from '../../../Components/Loader/PageLoader'

const AdminCategories = () => {
    const defaultQuery = {
        query: "",
        category_id: "",
    }
    const [query, setQuery] = useState(defaultQuery);
    const [queries, setQueries] = useState([]);
    const [orgData, setOrgData] = useState([]);
    const [selectedQuery, setSelectedQuery] = useState(0);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [IsDeleting, setIsDeleting] = useState(false);
    const [showForm, setShowForm] = useState(false);

    // Categories
    const [categories, setCategories] = useState([]);
    const [selectCategory, setSelectedCategory] = useState();


    const getCategories = () => {
        axios.get(`${Helpers.apiUrl}categories/all`,Helpers.authHeaders)
    .then(response => {
        const mappedCategories = response.data.map(category => ({
            label: category.name,
            category_id: category.id
        }));
        setCategories(mappedCategories);
        setOrgData(response.data);
    })
    .catch(error => {
        console.error("Error fetching categories:", error);
    });
    }

    const handleSelectedCategory = (selectedOption) => {
        console.log(selectedOption)
        const categoryId = selectedOption ? selectedOption.value : null;
        setQuery({ ...query, category_id: selectedOption.category_id });
    };

    const findCategory = (categories, query) => {
        const foundCategory = categories.find(category => category.category_id == query.category_id);
        return foundCategory ? foundCategory : 'Category not found';
    };

    const getQueries = () => {
        axios.get(`${Helpers.apiUrl}queries/all`,Helpers.authHeaders).then(response => {
            setQueries(response.data);
            setOrgData(response.data);
        });
    }

    const saveQuery = (e) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(`${Helpers.apiUrl}queries/save`, query, Helpers.authHeaders).then(response => {
            getQueries();
            Helpers.toast("success", response.data.message);
            hideShowForm();
            setIsLoading(false);
        }).catch(error => {
            Helpers.toast("error", error.response.data.message);
            setErrors(error.response.data.errors || {});
            setIsLoading(false);
        })
    }

    const handelEdit = selected_query => {
        const found = findCategory(categories, selected_query);
        console.log("Selected", selected_query);
        console.log(found)
        setSelectedCategory(found)
        setQuery(selected_query, found.category_id);
        setShowForm(true);
    }

    const initiateDelete = id => {
        setSelectedQuery(id);
    }

    const handleDelete = () => {
        setIsDeleting(true);
        axios.get(`${Helpers.apiUrl}queries/delete/${selectedQuery}`, Helpers.authHeaders).then(response => {
            Helpers.toast("success", response.data.message);
            getQueries();
            setSelectedQuery(0);
            setIsDeleting(false);
        }).catch(e=>{
            Helpers.toast("danger", e.message)
        });
    }

    const hideShowForm = () => {
        setQuery(defaultQuery);
        setErrors({});
        setShowForm(false);
    }

    useEffect(() => {
        getQueries();
        getCategories();
        return () => {
            getQueries();
            getCategories();
        };
    }, []);

    return (
        <div className="nk-content container-xl bg-white">
            <div className="container-xl">
            { (!(categories.length > 0 && queries.length > 0)) ? <PageLoader/> : (<div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-page-head">
                            <div className="nk-block-head-between">
                                <div className="nk-block-head-content">
                                    <h2 className="display-6">Queries</h2>
                                    <p>Manage all the Queries</p>
                                </div>
                                <div className="d-flex justify-content-end m-2">
                                <button className="btn btn-prim" onClick={() => setShowForm(true)}><FaPlusCircle /> Add Query</button>
                                </div>
                            </div>
                        </div>
                        {showForm && <div className="nk-block">
                            <div className="nk-block-head nk-block-head-sm">
                                <div className="nk-block-head-content"><h3 className="nk-block-title">Create New Query</h3></div>
                            </div>
                            <div className="card shadown-none">
                                <div className="card-body">
                                    <div className="row g-3 gx-gs">
                                        <form onSubmit={saveQuery}>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Query</label>
                                                    <div className="form-control-wrap">
                                                        <input type="text" value={query.query} onChange={e => setQuery({...query, query: e.target.value})} className="form-control" placeholder="Enter category name" />
                                                        <small className="text-danger">{ errors.name ? errors.name[0] : '' }</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Category</label>
                                                        <div className="form-control-wrap">
                                                        <Select
                                                            placeholder="Please Select the ID of the query"
                                                            defaultValue={selectCategory}
                                                            onChange={selectedOption => handleSelectedCategory(selectedOption)}
                                                            options={categories}
                                                        />
                                                        </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="col-md-12 d-flex justify-content-end">
                                            <button className="btn btn-prim m-1" onClick={saveQuery} disabled={isLoading}>{isLoading ? 'Saving...' : 'Save Query'}</button>
                                            <button className="btn btn-outline-second ml10 m-1" onClick={hideShowForm} disabled={isLoading}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {!showForm && (<div className="nk-block">
                            {/* <SearchHeader title={"All Categories"} orgData={orgData} setData={setCategories} columns={['name']} /> */}
                            <div className="card shadown-none">
                                <div className="card-body">
                                    <div className="row g-3 gx-gs">
                                        <div className="col-md-12">
                                        <div className="table-container" style={{maxHeight: "62vh",overflowY: "auto"}}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th>Sr. #</th>
                                                        <th>Query</th>
                                                        <th>Category Name</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {queries.length === 0 && <tr>
                                                        <td colSpan={4}>No records found...</td>
                                                    </tr>}
                                                    {queries.map((query, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{ index + 1 }</td>
                                                                <td>{ query.query }</td>
                                                                <td>{(findCategory(categories, query)).label}</td>
                                                                <td>
                                                                   {(selectedQuery && selectedQuery === query.id) ? <div>
                                                                        <button className="btn btn-outline-second btn-sm m-1" disabled={IsDeleting} onClick={() => handleDelete(query, query.category_id)}>
                                                                            <MdDeleteOutline /><span className="ml5">{IsDeleting ? 'Deleting...' : 'Yes, Delete'}</span>
                                                                        </button>
                                                                        <button className="btn btn-outline-prim btn-sm ml10 m-1" disabled={IsDeleting} onClick={() => setSelectedQuery(0)}>
                                                                            <IoMdClose /><span className="ml5">Cancel</span>
                                                                        </button>
                                                                   </div> : <div>
                                                                        <button className="btn btn-outline-prim btn-sm m-1" onClick={() => handelEdit(query)}>
                                                                            <FaRegEdit /><span className="ml5">Edit</span>
                                                                        </button>
                                                                        <button className="btn btn-outline-second btn-sm ml10 m-1" onClick={() => initiateDelete(query.id)}>
                                                                            <MdDeleteOutline /><span className="ml5">Delete</span>
                                                                        </button>
                                                                   </div>}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>
                </div>)}
            </div>
        </div>
    )
}

export default AdminCategories;