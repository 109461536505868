import React from 'react'
import { FaArrowRightLong } from "react-icons/fa6";

export default function Contact() {
  return (
    <div className='pt-5'><div className='pt-5'>
    <section className="service-style-three pb_150">
   <div className="auto-container">
     <div data-animation-box className="sec-title mb_55 centred">
       <span
         data-animation-text
         className="sub-title-three overlay-anim-white-bg"
         data-animation="overlay-animation"
       >
         Contact Details
       </span>
       <h2 className='fw-bold'> Get in Touch</h2>
       <p className='w-50 mx-auto'>We are here to assist you. Please feel free to reach out to us with any questions or inquiries. Your feedback is important to us, and we are committed to providing you with the information and support you need.</p>
     </div>

     <div className='row'>
        <div className='col-md-4 py-2'>
            <div className="card rounded-4 border-0 bg-lime-yellow p-2">
                <div className="card-body">
                    <h5 className="card-title text-center fs-2">Call Now</h5>
                    <p className="card-text">
                        General Inquiries:  +1 (888) 850-0025 
                        <br></br>
                        Technical Support: +1 (202) 888-5383
                    </p>
                    <div className='d-flex justify-content-center align-items-center p-3'>
                        <a className='button-purple fs-5' href='tel:+18888500025'>Call Now <FaArrowRightLong></FaArrowRightLong></a>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-md-4  py-2'>
            <div className="card rounded-4 border-0 bg-lime-yellow p-2">
                <div className="card-body">
                    <h5 className="card-title text-center fs-2">Email Support</h5>
                    <p className="card-text">
                        Prefer Email? Send us a Mail Now and we'll get back to you soon.
                    </p>
                    <div className='d-flex justify-content-center align-items-center p-3'>
                        <a className='button-purple fs-5' href="mailto:info@buildchampions.org">Send Email <FaArrowRightLong></FaArrowRightLong></a>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-md-4 py-2'>
            <div className="card rounded-4 border-0 bg-lime-yellow p-2">
                <div className="card-body">
                    <h5 className="card-title text-center fs-2">Visit Us</h5>
                    <p className="card-text">
                         We're Available during our Office Hours:
                         <br></br>
                        <br></br> 
                        Monday - Friday: 9:00 AM - 5:00 PM (Local Time)
                    </p>
                </div>
            </div>
        </div>
     </div>

     </div>
     </section>
     </div>
     </div>
  )
}
