import React, { useState, useEffect, useContext } from 'react'
import { ChatsContext } from "../../Contexts/ChatsContext";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Helpers from '../../Config/Helpers';
import formatDate from '../../Components/FormatDate';
import PageLoader from '../../Components/Loader/PageLoader';
import ExpirationAlert from '../../Components/ExpirationAlert'

export default function Chats() {
    const navigate = useNavigate();
    const { chats, setChats, showChat, setShowChat } = useContext(ChatsContext);

    
  const gotoChat = (url) => {
    setShowChat(true);
    navigate(url);
  };

  const getChats = () => {
    let userId = Helpers.getItem("id");
    axios.get(`${Helpers.apiUrl}chat/all/${userId}`, Helpers.authHeaders).then((response) => {
      setChats(response.data);
    });
  };

  const deleteChat = (chatId) => {
    axios.get(`${Helpers.apiUrl}chat/delete/${chatId}`, Helpers.authHeaders).then((response) => {
      Helpers.toast("success", response.data.message);
      setChats(response.data.chats);
    });
  };

  useEffect(()=>{
    getChats();
  },[])
  return (
    <div className='tyn-main ' id="tynMain" style={{overflow: 'auto', backgroundColor: 'white'}}>
          <div className="mt-4 mx-4">
              <ExpirationAlert />
          </div>
        
        <div 
        // className="tyn-section"
        >

            {chats.length < 0 ? <PageLoader/> : (<><div className='nk-head mx-4 my-2'>
                <h1 className='fw-bold'>Chat History</h1>
            </div>

        <div className="tyn-aside-body" data-simplebar >
            <ul className="tyn-aside-list">
              {chats.map((chat) => {
                if(chat.is_trial){
                  return;
                }
                return (
                <>
                  <div 
                  className='card p-2 my-3 mx-5 shadow-bottom-right rounded-4' 
                  key={chat.id} 
                  style={{border: "none"}}>
                    
                    <div className='card-body d-flex flex-row justify-content-between align-items-center'
                    style={{cursor: "pointer"}}
                    onClick={() => gotoChat(`/user/chat/${chat.chatId}`)}
                    >
                        <div>

                        <h3 className='fw-semibold fs-3'>
                        {chat.title}
                        </h3>

                        <p className='text-muted'>
                        {chat.chatId}
                        </p>

                        <p>
                        {formatDate(chat.created_at)}
                        </p>

                        </div>
                        <button
                        className="btn btn-icon btn-md btn-pill btn-light"
                        onClick={() => deleteChat(chat.id)}
                        >
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                        >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6a.5.5 0 0 0-1 0Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                    </button>
                    </div>
                  </div>
                  </>
                );
              })}
            </ul>
        </div></>)}

        </div>
    </div>
  )
}
